import React from 'react'
import Slider from 'react-slick'
const WhyChoose = ({ data }) => {
    let array = [
        {
            title: 'Industry-Education Partnerships',
            img: '../Assest/learnwithexperts.png',
            clr: 'yellowgrd',
            para: 'Between educational institutions and industry can lead to the development of programs that are tailored to the needs of employers.'
        },
        {
            title: 'Real-World Experience',
            img: '../Assest/learnAnything.png',
            clr: 'greengrd',

            para: 'Hands-on experiences through internships and projects prepare students for real-world challenges and problem-solving.'
        },
        {
            title: 'Project-Based Learning (PBL) Assignment',
            img: '../Assest/onlineCertificate.png',
            clr: 'bluegrd',

            para: 'Emphasizes learning through the completion of projects.'
        },
        {
            title: 'Work-Integrated Learning (WIL) Project',
            img: '../Assest/EmailMarketing.png',
            clr: 'redgrd',

            para: 'Highlights the integration of academic learning with practical work experience.'
        },
        {
            title: ' Code reviews and Mentorship',
            img: '../Assest/learnAnything.png',
            clr: 'yellowgrd',

            para: 'Participating in code reviews to improve coding practices ,Mentorship learning offers numerous advantages for both mentees and mentors.'
        },
        {
            title: 'Dual certification ',
            img: '../Assest/onlineCertificate.png',
            clr: 'greengrd',

            para: 'We provide dual certification which helps students to fill the Gap between industry expectations and College Passout.'
        },
        {
            title: 'Flexible Learning Model',
            img: '../Assest/EmailMarketing.png',
            clr: 'bluegrd',

            para: 'we provide Hybrid Model class, Online class , OFFline class as well as week end classes.'
        },
    ]
    var settings = {
        // dots: false,
        speed: 500,
        slidesToShow: 4,
        // rtl: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        arrows: false,
        // nextArrow: <SliderRight size={26} />,
        // prevArrow: <SliderLeft size={26} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div style={{ background: 'rgb(20,20,74)' }} className='text-white text-center my-20 '>
            <div className='container text-center text-white poppins mx-auto p-4 '>
                <p style={{ backgroundColor: 'rgb(88,81,225)' }} className='p-1 inter px-3 rounded-full mx-auto bg-violet-400 w-fit text-white'>Why Choose  </p>
                <p className='text-2xl lg:text-3xl fw-semibold '>
                    SLA For {data && data} ?
                </p>
                <p className='text-slate-400 mx-auto text-xs '>
                    We make learning easy and engaging! With flexible options and personal support, <br />
                    we’ll help you succeed in your future career.</p>

                <main>
                    <Slider {...settings} >
                        {
                            array.map((obj) => (
                                <div className='flex w-2/5 lg:w-1/5 flex-col  items-center'>
                                    <img loading='lazy' width={70} src={obj.img} className='mx-auto' alt="Learn With Experts" />
                                    <p className='text-md fw-semibold mt-3 mb-1'>{obj.title} </p>
                                    <p className='text-slate-400 text-sm'>{obj.para} </p>
                                </div>
                            ))
                        }
                    </Slider>
                </main>
            </div>
        </div>
    )
}

export default WhyChoose