import React, { useState } from 'react'
import CropCircle2 from '../Component/CourseComponent/CropCircle2'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import Blogs from '../Component/HomeComponent/Blogs'
import EventsNews from '../Component/HomeComponent/EventsNews'

const WhySLA = () => {
    let [activeCircle, setActiveCircle] = useState(0)
    let data = [
        {
            heading: 'Structured Learning : ',
            para: `Skill learning academy provides structured learning programs designed to take you from beginner to advanced levels in a particular skill. This structure can help you stay focused and motivated as you 
            progress.`
        },
        {
            heading: 'Expert Guidance :',
            para: `Skill learning Academy typically employs experienced instructors who are experts in their respective fields. Their guidance can accelerate your learning process, provide valuable insights, and help you avoid common 
            pitfalls.`
        },
        {
            heading: 'Hands-on Experience :  ',
            para: `Skill learning academy emphasizes hands-on learning, allowing you to practice and apply what you've learned in real-world scenarios. This practical experience is invaluable for mastering a 
            skill.`
        },
        {
            heading: 'Networking Opportunities :  ',
            para: `Academy often brings together individuals with similar interests and goals. This creates opportunities to network with peers, exchange ideas, and potentially collaborate on 
            projects.`
        },
        {
            heading: 'Access to Resources :  ',
            para: `Academy provides access to resources such as textbooks, online tutorials, practice exercises, and specialized equipment or software that you may not have access to 
            otherwise.`
        }, {
            heading: 'Recognition and Credentials :',
            para: `Completing a program from Skill learning academy can provide you with recognized credentials or certifications, which can enhance your resume and credibility in the 
            field.`
        }, {
            heading: 'Personalized Feedback  : ',
            para: `nstructors at skill learning academy can provide personalized feedback on your progress, helping you identify areas for improvement and tailor your learning experience to meet your specific 
            needs.`
        }, {
            heading: 'Motivation and Accountability : ',
            para: `Being part of a structured learning environment with deadlines and expectations can help keep you accountable and motivated to continue learning and improving your skills.`
        }, {
            heading: 'Soft skills : ',
            para: `Skill learning academy not only equips you with technical skills but also trains you in soft skills so that you are better equipped to face interviews and shine with a personality that makes you ready to get a job and adjust to the work 
            atmosphere.`
        },
    ]
    let sixPoints = [
        {
            heading: 'Initial Assessment',
            short: '1: Assessment',
            para: `We begin with a brief assessment to understand your needs and help you choose the right technology. Our expert counselors guide you in 
            selecting the best course tailored to your goals.`
        },
        {
            heading: 'Expert Training',
            short: '2: Training ',
            para: `Gain theoretical knowledge from industry experts through our well-structured training programs. Learn from professionals who share real-world insights 
            and expertise.`
        },
        {
            heading: 'Hands-On Experience',
            short: '3: Experience  ',
            para: `Apply what you learn with practical training that mirrors real-life scenarios. Our hands-on approach ensures you're job-ready from day one, bridging
             the gap between theory and practice.`
        },
        {
            heading: 'Essential Soft Skills ',
            short: '4: Soft Skills ',
            para: `Develop crucial soft skills like communication and problem-solving. We focus on these skills to prepare you for success in any
             professional setting.`
        },
        {
            heading: 'Interview Prep',
            short: '5: Prep',
            para: `Get ready for job interviews with our specialized preparation sessions. We provide resources and tips to boost your confidence. Our team at Merida HR will assist 
            you in discovering job openings.`
        },
        {
            heading: 'Resource Quality Gap',
            short: '6: RQG ',
            para: `We close the Resource Quality Gap by delivering training that aligns with industry standards. Our programs ensure you acquire the
             skills that employers are actively seeking.`
        },
    ]
    return (
        <div className='min-h-[50vh] bgsec' >
            <> <Helmet>
                <title>Choose SkillLearningAcademy for Your Training Needs and Resources     </title>
                <meta name="description" content="Get our best services in our expertise and commitment to excellence in every interaction for industry-leading training, support, and training resources to your 
                success." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href={`${domain}/why-skilllearningacademy`} />
            </Helmet>
                <h1 className='d-none'>best training company </h1>
                <h2 className='d-none'>best training institute </h2>
            </>
            <img src={require('../Assest/whyslaBanner.webp')} className='w-full ' alt="WhySla" />
            {/* Content */}
            <section className='relative w-full mt-10 my-3 sm:mt-0 pt-8 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-7xl xl:text-8xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    Our Approach

                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                        How We Guide You
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
                        clear path
                        <img src={require('../Assest/arrows.png')} alt="Arrow"
                            className='w-[50px]' />
                    </h6>
                </div>
            </section>
            <main className='container my-5 ' >
                <div className='col-lg-8 col-xl-8 mx-auto p-4 flex' >
                    <CropCircle2 activeCircle={activeCircle} scroll='whyslascroll'
                        setActiveCircle={setActiveCircle} whyChooseData={sixPoints} />
                </div>
                <div id='whyslascroll' className=' my-10 rounded contentbg p-3 poppins' >
                    <h5 className='text-violet-800 ' > {sixPoints[activeCircle].heading} </h5>
                    <p className='my-2 text-sm' > {sixPoints[activeCircle].para}  </p>

                </div>
                <div className='rounded flex my-3 bg-white '>
                    <div className='  p-2 p-sm-5 '>
                        {
                            [...data].map((x) => (
                                <div>
                                    <h5 className='text-violet-800'> {x.heading} </h5>
                                    <p> {x.para} </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </main>
            <EventsNews />
            <Blogs />
        </div>
    )
}

export default WhySLA