import axios from 'axios'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { hostname } from '../../App'
import { toast } from 'react-toastify'
import CheckBox from '../../Icons/CheckBox'
import ReCAPTCHA from 'react-google-recaptcha'

const ContactModel = (props) => {
    const { show, setShow, courseName } = props
    let [loading, setloading] = useState(false)
    let [contactinfo, setContactInfo] = useState({
        firstname: '',
        lastname: '',
        email: '',
        mobile_number: '',
        terms: false,
        captcha: false
    })
    const handleContact = (e) => {
        let { value, name } = e.target
        if (name == 'mobile_number' && value.length > 10) {
            value = contactinfo.mobile_number
        }
        setContactInfo((prev) => ({
            ...prev,
            [name]: value
        }))

    }
    const handleContactform = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (contactinfo.captcha) {


            if (contactinfo.firstname != '' && emailRegex.test(contactinfo.email) &&
                contactinfo.mobile_number != '' && contactinfo.terms) {
                setloading(true)
                document.getElementById('errormsg23').innerHTML = ``
                axios.post(`${hostname}/event/BrocherView/`, contactinfo).then((response) => {
                    console.log(response.data);
                    setloading(false)
                    setShow(false)
                    setContactInfo({
                        firstname: '',
                        lastname: '',
                        email: '',
                        mobile_number: '',
                        terms: false
                    })
                    toast.success('Brochure will be recieve soon..')
                }).catch((error) => {
                    setloading(false)
                    // toast.warning('Enter the required field')
                    console.log(error);
                })
            }
            else {
                document.getElementById('errormsg23').innerHTML =
                    `*Complete the above form and click the 'I agree' check mark  `
            }
        } else {
            document.getElementById('errormsg23').innerHTML =
                `*Check the Captcha  `
        }
    }
    return (
        <div>
            <Modal centered className='bg-blue-600 bg-opacity-5 inter   '
                size='' show={show} onHide={() => setShow(false)} >
                <div className='p-1 rounded-2xl text-center '>
                    <div className='grd3 p-3 rounded-t-2xl    '>
                        <img src={require('../../Assest/SLA_logo_Blue.png')} loading='lazy'
                            className='mx-auto w-[150px] ' alt="Merida Logo" />
                        <h4 className='mx-auto my-4 w-fit fw-semibold text-blue-600 '>{courseName} </h4>
                        <h6>Why You Should Learn With Merida ? </h6>
                        <section className='flex my-2 container-sm  flex-wrap mx-auto col-sm-11 text-sm'>
                            <div className='flex my-2 text-slate-600 text-xs  items-center w-1/2'>
                                <CheckBox />
                                Assured Internship
                            </div>
                            <div className='flex my-2 text-slate-600 text-xs  items-center w-1/2'>
                                <CheckBox />
                                Flexible Class
                            </div>
                            <div className='flex my-2 text-slate-600 text-xs items-center w-1/2'>
                                <CheckBox />
                                Project Based Internship
                            </div>
                            <div className='flex my-2 text-slate-600 text-xs  items-center w-1/2'>
                                <CheckBox />
                                Mock Interviews
                            </div>
                            <div className='flex my-2 text-slate-600 text-xs  items-center w-1/2'>
                                <CheckBox />
                                Learn from Developers
                            </div>
                            <div className='flex my-2 text-slate-600 text-xs  items-center w-1/2'>
                                <CheckBox />
                                One on one classes
                            </div>
                        </section>
                    </div>
                    {/* INput */}
                    <div className='p-3 text-sm'>
                        <div className='flex justify-between'>
                            <input style={{ backgroundColor: 'rgb(236,244,250)' }} type="text" className='border-1 w-40 sm:w-fit outline-none border-slate-300 rounded  p-2 '
                                value={contactinfo.firstname} name='firstname' onChange={handleContact} placeholder='First Name*' />
                            <input style={{ backgroundColor: 'rgb(236,244,250)' }} value={contactinfo.lastname} name='lastname' onChange={handleContact}
                                type="text" className='border-1 w-40 sm:w-fit outline-none border-slate-300 rounded  p-2 ' placeholder='Last Name' />
                        </div>
                        <input style={{ backgroundColor: 'rgb(236,244,250)' }} type="email" value={contactinfo.email} name='email' onChange={handleContact}
                            className='border-1 outline-none border-slate-300 w-full my-4 rounded  p-2 ' placeholder='Email*' />
                        <input style={{ backgroundColor: 'rgb(236,244,250)' }} type="number" value={contactinfo.mobile_number} name='mobile_number' onChange={handleContact}
                            className='border-1 outline-none border-slate-300 w-full rounded  p-2 ' placeholder='Phone Number*' />
                        <div className='p-2 flex items-center justify-center'>
                            <ReCAPTCHA
                                className=' md:scale-[0.80] inputbg '
                                spellCheck={contactinfo.captcha}
                                sitekey="6Ledg_QpAAAAAKgVH6WcItGafyMHUtoShRHFmMVc"
                                onChange={() => {
                                    setContactInfo((prev) => ({
                                        ...prev,
                                        captcha: !contactinfo.captcha
                                    }))
                                }}
                            />
                        </div>
                        <p className='text-red-600 text-center h-[20px] text-xs my-2 ' id="errormsg23">

                        </p>
                        {/* Button download */}
                        <button onClick={handleContactform} disabled={loading} className='w-full text-white rounded p-[10px] bg-blue-700 '>
                            {loading ? 'Loading...' : "Download now"}</button>

                        <div className='my-2 flex items-center justify-center gap-2'>
                            <input type="checkbox" checked={contactinfo.terms} onChange={() => {
                                setContactInfo((prev) => ({
                                    ...prev,
                                    terms: contactinfo.terms ? false : true
                                }))
                            }}
                                id='check' />
                            <label htmlFor="check" className='text-xs'>I agree with Merida <button className='text-blue-600'>Privacy Policy </button> &
                                <button className='text-blue-600'> Terms and conditions </button>
                            </label>
                        </div>
                        <p className='text-center text-slate-600 text-xs'> We do not spam or sell your data to anyone </p>

                    </div>
                </div>
            </Modal>


        </div>
    )
}

export default ContactModel