import React from 'react'
import ArrowDown from '../../Icons/ArrowDown'

const CourseReview = () => {
    return (
        <div className='py-5 flex relative'>
            <img src={require('../../Assest/Shape1.png')} alt="Shape1"
                className='left-0 top-5 w-20 absolute ' />
            <img src={require('../../Assest/moon1.png')} alt="Moon1"
                className='right-0 bottom-5 w-36 absolute ' />
            <main className='row container m-auto ' >

                <section className='col-md-6 relative  my-4  ' >
                    <p className=' text-2xl fw-semibold text-blue-800'>
                        What our student's say's
                    </p>
                    <section className='relative w-full mt-10 sm:mt-0 pt-8 sm:pt-16 poppins'>
                        <h6 style={{ zIndex: 0 }}
                            className='text-5xl sm:text-7xl xl:text-9xl text-nowrap fw-bold absolute top-0 txtbgz'>
                            Reviews
                        </h6>
                        <div style={{ zIndex: 6 }} className='relative '>
                            <h4 className=' text-3xl sm:text-5xl poppins fw-semibold text-blue-800'>
                                Feedbacks
                            </h4>
                        </div>
                    </section>
                    <p className='text-sm text-slate-400 w-8/12 ' >
                        I’ve wanted to learn digital marketing for a while, and SkillLearningAcademy made it possible. The on-the-job training gave me hands-on experience, and the projects helped me apply what I learned in real-world situations.
                        A big thanks to Mr. Athirupan, the CEO, for his support, and to my mentor, Mr. Karthik, for simplifying everything with real-life examples.
                        I’m also grateful to the HR team for their help with job opportunities. Overall, it’s been an amazing learning journey! </p>

                    <a href="" className='text-decoration-none ' >
                        <button className='text-white bg-violet-800 text-decoration-none rounded p-2 flex items-center gap-2 ' >
                            Check All Reviews
                            <span className=' -rotate-90 ' >  <ArrowDown />
                            </span>
                        </button>
                    </a>
                    {/* <img src={require('../../Assest/arrows2.png')} alt="Arrow" className='absolute  -rotate-180  right-1/2 ' /> */}
                </section>
                <section className='col-md-6 flex my-4 ' >

                    <div className='w-[300px] mx-auto h-[300px] relative sm:w-[400px] sm:h-[400px] reviewshadow rounded bg-red-50  ' >
                        <iframe className='w-full h-full rounded '
                            src="https://www.youtube.com/embed/9AhbgzVqhJY?si=FFXpwr9KZf7bGO-o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>

                        </iframe>
                    </div>
                </section>

            </main>

        </div>
    )
}

export default CourseReview