import React from 'react'

const TvIcon = ({ size }) => {
    return (
        <svg width={size ? size : "512"} height={size ? size : "512"} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M456.502 350.516H447.724V101.648H456.502C466.559 101.648 474.788 93.4191 474.788 83.3622C474.788 73.3052 466.559 65.0765 456.502 65.0765H278.581V54.8365C278.581 44.7796 270.354 36.5508 260.296 36.5508C250.056 36.5508 242.01 44.7796 242.01 54.8365V65.0765H55.4967C45.4386 65.0765 37.2109 73.3052 37.2109 83.3622C37.2109 93.4191 45.4386 101.648 55.4967 101.648H64.2734V350.516H55.4967C45.4386 350.516 37.2109 358.562 37.2109 368.802C37.2109 378.859 45.4386 387.088 55.4967 387.088H188.616L156.25 448.71C151.497 457.67 154.97 468.642 163.931 473.397C172.891 477.968 183.862 474.677 188.616 465.716L229.942 387.088H298.147L339.473 465.716C343.743 473.783 353.927 478.172 364.158 473.397C373.119 468.642 376.594 457.67 371.839 448.71L339.473 387.088H456.502C466.559 387.088 474.788 378.859 474.788 368.802C474.788 358.562 466.559 350.516 456.502 350.516ZM370.26 187.403L299.135 281.287C292.312 290.31 278.717 291.136 270.93 282.43L216.769 221.814L171.394 287.912C165.691 296.237 154.273 298.356 145.974 292.636C137.644 286.921 135.528 275.546 141.251 267.216L199.805 181.912C202.974 177.287 208.108 174.368 213.706 174.011C219.34 173.546 224.769 175.895 228.51 180.073L283.403 241.502L341.117 165.314C347.198 157.278 358.662 155.653 366.733 161.787C374.778 167.886 376.358 179.35 370.26 187.403Z"
                fill="currentColor" />
        </svg>

    )
}

export default TvIcon