import React from 'react'

const TwitterIcon = ({ size }) => {
    return (
        <svg width={size ? size : "19"} height={size ? size : "19"} viewBox="0 0 19 19" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M11.14 7.95303L17.9819 -0.00012207H16.3606L10.4198 6.90549L5.67485 -0.00012207H0.202148L7.37739 10.4424L0.202148 18.7825H1.82355L8.09721 11.4899L13.1082 18.7825H18.5809L11.1396 7.95303H11.14ZM8.91926 10.5344L8.19226 9.49455L2.40777 1.22044H4.89815L9.5663 7.89791L10.2933 8.93775L16.3613 17.6174H13.871L8.91926 10.5348V10.5344Z" />
        </svg>

    )
}

export default TwitterIcon