import React from 'react'
import Slider from 'react-slick'

const CertificateSliders = () => {
    let normal = [
        '../../Assest/certificate.png',
        '../../Assest/certificate1.jpg',
        '../../Assest/certificate2.jpg',
        '../../Assest/certificate3.jpg',

    ]
    var settings2 = {
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        className: "center",
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='xl:min-h-[88vh] relative bg-blue-50 py-3 ' >
            <img src={require('../../Assest/cube2.png')} alt="Cube 2" loading='lazy' className='w-28 absolute top-5 right-10 ' />
            <img src={require('../../Assest/bgShade.png')} loading='lazy'
                alt="Shade" className='absolute top-0 left-0 w-52 ' />
            <section className='relative w-full mt-10 sm:mt-0 pt-8 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-9xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    Achievements
                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                        Earn Your Certification Today
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
                        Trusted & Recognized
                        <img src={require('../../Assest/arrows.png')} alt="Arrow"
                            className='w-[50px]' loading='lazy' />
                    </h6>
                </div>
            </section>
            {/* Slider */}

            <main className=' relative container mx-auto ' >
                <div className='absolute -top-5 -z-0 -left-1 sm:-left-2 bg-blue-300 rounded-xl w-20 h-20 ' >
                </div>
                <div className='absolute -bottom-5 -z-0 right-0 sm:-right-2 bg-violet-300 rounded-xl w-40 h-40 ' >
                </div>
                <div className='relative rounded-xl z-10 p-3 bg-white container mx-auto ' >
                    <section className='rounded-xl overflow-hidden relative' >
                        <img src={require('../../Assest/Ellipse6.png')} alt="Shade"
                            className=' absolute right-0 top-0 ' loading='lazy' />
                        <img src={require('../../Assest/Ellipse5.png')} alt="Shade"
                            className=' absolute left-0 bottom-0 ' loading='lazy' />
                        <div className="slider-container my-auto ">
                            <Slider {...settings2} className='my-auto xl:min-h-[60vh]   ' >
                                {
                                    [...normal, ...normal].map((x, index) => {
                                        return (
                                            <div className='mt-24 relative'>
                                                <img loading='lazy' src={x}
                                                    className='slider-item w-full rounded-xl ' alt='certificate' />
                                            </div>
                                        )
                                    })
                                }
                            </Slider>

                        </div>

                    </section>

                </div>

            </main>



        </div>
    )
}

export default CertificateSliders