import React from 'react'

const IdCardIcon = ({ size }) => {
    return (
        <svg width={size ? size : "512"} height={size ? size : "512"} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M255.867 296H231.488C215.992 296.02 202.184 305.781 197 320.379H290.355C285.168 305.781 271.359 296.02 255.867 296Z" fill="currentColor" />
            <path d="M261.998 265.143C261.998 275.241 253.813 283.427 243.714 283.427C233.615 283.427 225.43 275.241 225.43 265.143C225.43 255.044 233.615 246.855 243.714 246.855C253.813 246.855 261.998 255.044 261.998 265.143Z" fill="currentColor" />
            <path d="M325.999 192H161.427C156.379 192 152.283 196.093 152.283 201.144V438.856C152.283 443.907 156.379 447.999 161.427 447.999H325.999C331.047 447.999 335.143 443.907 335.143 438.856V201.144C335.143 196.093 331.05 192 325.999 192ZM289.428 411.428H198.001C192.95 411.428 188.857 407.335 188.857 402.284C188.857 397.237 192.95 393.141 198.001 393.141H289.428C294.479 393.141 298.572 397.237 298.572 402.284C298.572 407.335 294.479 411.428 289.428 411.428ZM289.428 374.857H198.001C192.95 374.857 188.857 370.764 188.857 365.713C188.857 360.665 192.95 356.57 198.001 356.57H289.428C294.479 356.57 298.572 360.665 298.572 365.713C298.572 370.764 294.479 374.857 289.428 374.857ZM289.428 338.285H198.001C192.95 338.285 188.857 334.193 188.857 329.142C188.904 311.42 199.202 295.324 215.275 287.851C210.048 281.435 207.177 273.419 207.142 265.143C207.142 244.942 223.516 228.568 243.713 228.568C263.913 228.568 280.287 244.942 280.287 265.143C280.249 273.419 277.381 281.432 272.154 287.851C288.224 295.324 298.522 311.42 298.572 329.142C298.572 334.193 294.479 338.285 289.428 338.285Z" fill="currentColor" />
            <path d="M352.197 68.5557C350.56 65.7344 347.545 64 344.287 64H307.713C304.432 64 301.405 65.7578 299.776 68.6084L254.246 148.29L268.772 173.714H297.345L352.224 77.6787C353.841 74.8486 353.829 71.374 352.197 68.5557Z" fill="currentColor" />
            <path d="M247.713 173.714L187.651 68.6084C186.025 65.7578 182.993 64 179.715 64H143.144C138.093 64 134 68.0928 134 73.1436C134 74.7344 134.416 76.2959 135.204 77.6787L190.083 173.714H247.713Z" fill="currentColor" />
        </svg>
    )
}

export default IdCardIcon