import React from 'react'

const BannerCourseCard = ({ heading, content }) => {
    return (
        <div className='w-[12rem] rounded shadow p-3 relative coursecard' >
            <div className='w-3 absolute top-2 left-2 shadow-md h-3 rounded-full bg-white ' >
                <hr className='m-0 rotate-45 relative top-[5.5px] left-[1px] w-[0.65rem]  ' />
            </div>
            <div className='w-3 absolute h-3 right-2 shadow-md top-2 rounded-full bg-white ' >
                <hr className='m-0 rotate-45 relative top-[5.5px] left-[1px] w-[0.65rem]  ' />
            </div>
            <div className='w-3 absolute bottom-2 left-2 shadow-md h-3 rounded-full bg-white ' >
                <hr className='m-0 rotate-45 relative top-[5.5px] left-[1px] w-[0.65rem]  ' />
            </div>
            <div className='w-3 absolute h-3 right-2 shadow-md bottom-2 rounded-full bg-white ' >
                <hr className='m-0 rotate-45 relative top-[5.5px] left-[1px] w-[0.65rem]  ' />
            </div>
            <p className='text-lg mb-1 text-white fw-semibold  '>
                {heading} </p>
            <p className=' text-opacity-80 text-sm text-slate-700 ' > {content} </p>
        </div>

    )
}

export default BannerCourseCard