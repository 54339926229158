import React, { useEffect, useState } from 'react'
import ArrowDown from '../../Icons/ArrowDown'
import LikeIcon from '../../Icons/LikeIcon'
import QuotesLeft from '../../Icons/QuotesLeft'

const CourseTestimonial = () => {
    let [selectedIndex, setSelectedIndex] = useState(0)
    let [animation, setanimation] = useState()
    let testimonial = [
        {
            name: 'Pragathi S',
            work: 'Working at Merida Skill Learning Academy',
            cmnt: `I’ve wanted to learn digital marketing for a while, and SkillLearningAcademy made it possible. The on-the-job training gave me hands-on experience, and the projects helped me apply what I learned in real-world situations.
A big thanks to Mr. Athirupan, the CEO, for his support, and to my mentor, Mr. Karthik, for simplifying everything with real-life examples. 
I’m also grateful to the HR team for their help with job opportunities. Overall, it’s been an amazing learning journey!`
        },
        {
            name: 'Pradeep',
            work: 'Working at Merida Skill Learning Academy',
            cmnt: `As an MBA graduate, I wanted to add digital marketing skills to advance my career and offer freelance services. SkillLearningAcademy was the perfect choice! My trainer, Mr. Karthik, was an expert, sharing practical insights and teaching effective strategies. He not only taught me well but also shared his own experiences, which made learning so much more relatable.
I highly recommend SkillLearningAcademy’s Digital Marketing course to anyone looking to excel in this field!`
        },
        {
            name: 'Syed Abdul Fathah',
            work: 'Working at Merida Skill Learning Academy',
            cmnt: `I’ve always been passionate about digital marketing and wanted to dive deeper into it. Thanks to SkillLearningAcademy and their digital marketing course, I was able to do just that. The three months of on-the-job training gave me hands-on experience with Google AdWords, pay-per-click, SEO, SMM, and social media management. I even worked with real 
            clients.`
        }
    ]
    useEffect(() => {

        setTimeout(() => {
            setanimation('')
        }, 2000);
    }, [selectedIndex])
    return (
        <div className='py-4 poppins coursetestimonialbg ' >
            <main className='overflow-hidden container mx-auto' >
                <main className={` ${animation} container mx-auto row p-4 `} >
                    <section className='col-md-6 ' >
                        <h6 className='text-3xl sm:text-5xl fw-normal '>
                            {testimonial[selectedIndex].name} </h6>
                        {/* <p className='text-xl '>{testimonial[selectedIndex].work} </p> */}
                        <hr className='border-2 w-32 border-slate-950 opacity-100 ' />
                        <img src={require('../../Assest/left_quotes.png')} alt="Quotes"
                            className=' w-[15rem] mx-auto ' />
                    </section>
                    <section className='col-lg-6 p-4 ' >
                        <span style={{ color: '#FFC881' }} className=' my-4' >
                            <QuotesLeft />
                        </span>
                        <p className='text-slate-50 sm:text-xl my-4 linespacing ' >
                            {testimonial[selectedIndex].cmnt} </p>
                        <div className=' text-violet-600 flex items-center gap-3 ms-auto w-fit' >
                            <LikeIcon /> <span className='text-slate-950 fw-semibold text-xl sm:text-2xl ' >
                                Testimonial
                            </span>
                        </div>
                    </section>
                </main>
                <article className=' flex gap-3 items-center ' >
                    <button onClick={() => {
                        setanimation('slideInRight')

                        if (selectedIndex == 0)
                            setSelectedIndex(testimonial.length - 1)
                        else
                            setSelectedIndex((prev) => prev - 1)
                    }} className='rotate-90 text-violet-600 fw-bold ' >
                        <ArrowDown size={30} />
                    </button>
                    <div>
                        <span className='text-3xl sm:text-5xl text-violet-600 ' > {(1 + selectedIndex + '').padStart(2, 0)} </span>
                        <span className='text-xl sm:text-2xl text-violet-600' >/{(testimonial.length + '').padStart(2, 0)} </span>
                    </div>

                    <button onClick={() => {
                        setanimation('slideInleft')
                        if (selectedIndex == testimonial.length - 1)
                            setSelectedIndex(0)
                        else
                            setSelectedIndex((prev) => prev + 1)
                    }}
                        className='-rotate-90 text-violet-600 fw-bold ' >
                        <ArrowDown size={30} />
                    </button>
                </article>
            </main>
        </div>
    )
}

export default CourseTestimonial