import axios from 'axios'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { hostname } from '../App'
import ReCAPTCHA from 'react-google-recaptcha'

const InstructorModal = (props) => {
    let { show, setShow } = props
    let [instructorForm, setInstructorForm] = useState({
        name: '',
        email: '',
        phone: '',
        skill: '',
        experience: '',
        captcha: false
    })
    let handleChange = (e) => {
        let { name, value } = e.target
        if (name == 'phone' && value.length > 10) {
            value = instructorForm.phone
        }
        if (name == 'experience' && Number(value) < 0) {
            value = 0
        }
        if (name == 'experience' && value > 99) {
            value = 99
        }
        setInstructorForm((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let [loading, setLoading] = useState(false)
    let submitForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (instructorForm.captcha) {

            if (instructorForm.name != '' && instructorForm.email != '' && instructorForm.experience != '' && instructorForm.skill && instructorForm.phone != '') {
                if (emailRegex.test(instructorForm.email)) {
                    setLoading(true)
                    axios.post(`${hostname}/blog/instructor`, instructorForm).then((response) => {
                        console.log(response.data);
                        setLoading(false)
                        setShow(false)
                        setInstructorForm({
                            name: '',
                            email: '',
                            phone: '',
                            skill: '',
                            experience: ''
                        })
                        toast.success('Thank you for your time, Our Team will get you soon.')
                    }).catch((error) => {
                        console.log(error);
                        setLoading(false)

                    })
                }
                else {
                    document.getElementById('errorInstructor').innerHTML = 'Enter the proper mail'

                }
            }
            else {
                document.getElementById('errorInstructor').innerHTML = 'Enter all the fields'
            }
        }
        else {
            document.getElementById('errorInstructor').innerHTML = 'Verify the Captcha'

        }
    }
    return (
        <div>
            {show && <Modal centered show={show} onHide={() => setShow(false)} >
                <Modal.Header>
                    <p className='fw-semibold mb-0 mx-auto text-xl'>Become an Instructor</p>
                </Modal.Header>
                <Modal.Body className=''>
                    <input value={instructorForm.name} onChange={handleChange} name='name'
                        type="text" placeholder='Name' className='outline-none p-2 rounded border-2 w-10/12 my-4 mx-auto flex border-slate-500' />
                    <input value={instructorForm.email} onChange={handleChange} name='email'
                        type="email" placeholder='Email' className='outline-none p-2 rounded border-2 w-10/12 my-4 mx-auto flex border-slate-500' />
                    <input value={instructorForm.phone} onChange={handleChange} name='phone'
                        type="number" placeholder='Phone Number' className='outline-none p-2 rounded border-2 w-10/12 my-4 mx-auto flex border-slate-500' />
                    <input value={instructorForm.skill} onChange={handleChange} name='skill'
                        type="text" placeholder='Skills' className='outline-none p-2 rounded border-2 w-10/12 my-4 mx-auto flex border-slate-500' />
                    <input value={instructorForm.experience} onChange={handleChange} name='experience'
                        type="number" placeholder='Experience in years' className='outline-none p-2 rounded border-2 w-10/12 my-4 mx-auto flex border-slate-500' />
                    <div className='p-2 flex items-center justify-center'>
                        <ReCAPTCHA
                            className=' md:scale-[0.80] inputbg '
                            spellCheck={instructorForm.captcha}
                            sitekey="6Ledg_QpAAAAAKgVH6WcItGafyMHUtoShRHFmMVc"
                            onChange={() => {
                                setInstructorForm((prev) => ({
                                    ...prev,
                                    captcha: !instructorForm.captcha
                                }))
                            }}
                        />
                    </div>
                    <p className=' text-red-600 text-center h-[20px]' id='errorInstructor' > </p>
                    <button disabled={loading} onClick={submitForm} className=' w-10/12 mx-auto p-2 flex text-center justify-center rounded bg-blue-600 text-white '>
                        {loading ? "Loading.." : "Submit"} </button>
                </Modal.Body>
            </Modal>}

        </div>
    )
}

export default InstructorModal