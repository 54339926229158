import React from 'react'

const ClockIcon = ({ size }) => {
    return (
        <svg width={size ? size : "18"} height={size ? size : "18"} viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.23645 17.1399C7.6806 17.1399 6.24808 16.7604 4.93889 16.0015C3.6297 15.2425 2.59562 14.2084 1.83667 12.8992C1.07772 11.5901 0.698242 10.1575 0.698242 8.60168C0.698242 7.04583 1.07772 5.61331 1.83667 4.30411C2.59562 2.99492 3.6297 1.96085 4.93889 1.2019C6.24808 0.442944 7.6806 0.063467 9.23645 0.063467C10.7923 0.063467 12.2248 0.442944 13.534 1.2019C14.8432 1.96085 15.8773 2.99492 16.6362 4.30411C17.3952 5.61331 17.7747 7.04583 17.7747 8.60168C17.7747 10.1575 17.3952 11.5901 16.6362 12.8992C15.8773 14.2084 14.8432 15.2425 13.534 16.0015C12.2248 16.7604 10.7923 17.1399 9.23645 17.1399ZM9.23645 1.14497C7.87034 1.14497 6.61807 1.47702 5.47964 2.1411C4.34121 2.80518 3.43996 3.70644 2.77587 4.84487C2.11179 5.98329 1.77975 7.23557 1.77975 8.60168C1.77975 9.96779 2.11179 11.2201 2.77587 12.3585C3.43996 13.4969 4.34121 14.3982 5.47964 15.0623C6.61807 15.7263 7.87034 16.0584 9.23645 16.0584C10.6026 16.0584 11.8548 15.7263 12.9933 15.0623C14.1317 14.3982 15.033 13.4969 15.697 12.3585C16.3611 11.2201 16.6932 9.96779 16.6932 8.60168C16.6932 7.23557 16.3611 5.98329 15.697 4.84487C15.033 3.70644 14.1317 2.80518 12.9933 2.1411C11.8548 1.47702 10.6026 1.14497 9.23645 1.14497ZM12.9933 13.2123C12.8415 13.2123 12.7087 13.1554 12.5948 13.0416L8.83801 9.34166C8.76211 9.22782 8.72416 9.095 8.72416 8.94321V3.99104C8.72416 3.83925 8.7716 3.70644 8.86647 3.5926C8.96134 3.47875 9.08466 3.42183 9.23645 3.42183C9.38825 3.42183 9.51157 3.47875 9.60644 3.5926C9.70131 3.70644 9.74875 3.83925 9.74875 3.99104V8.71552L13.3348 12.3016C13.4486 12.4154 13.5056 12.5482 13.5056 12.7C13.5056 12.8518 13.4581 12.9751 13.3633 13.07C13.2684 13.1649 13.1451 13.2123 12.9933 13.2123Z"
                fill="currentColor" />
        </svg>

    )
}

export default ClockIcon