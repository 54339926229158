import React, { useEffect, useState } from 'react'
import HomeBanner from '../Component/HomeComponent/HomeBanner'
import CourseDetails from '../Component/CourseComponent/CourseDetails'
import CoursePlan from '../Component/CourseComponent/CoursePlan'
import JourneyCourse from '../Component/CourseComponent/JourneyCourse'
import WhyChoose from '../Component/CourseComponent/WhyChoose'
import CertificateSliders from '../Component/CourseComponent/CertificateSliders'
import OtherCourses from '../Component/CourseComponent/OtherCourses'
import CourseReview from '../Component/CourseComponent/CourseReview'
import CourseTestimonial from '../Component/CourseComponent/CourseTestimonial'
import FAQ from '../Component/CourseComponent/FAQ'
import BecomeInstructor from '../Component/HomeComponent/BecomeInstructor'
import Footer from '../Component/Footer'
import Tags from '../Component/CourseComponent/Tags'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { domain, hostname } from '../App'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const ParticularCourse = () => {
    let { slug } = useParams()
    let navigate = useNavigate()
    let [loading, setloading] = useState()
    let [data, setData] = useState()
    let [animation, setanimation] = useState('fadein2')
    let getData = () => {
        setloading(true)
        if (slug)
            axios.get(`${hostname}/blog/course/${slug}/`).then((response) => {
                console.log("jeool", response.data);
                setData(response.data)
                setloading(false)
            }).catch((error) => {
                console.log(error);
                setloading(false)
                // navigate('/notFound')
            })
    }
    useEffect(() => {
        getData()
        window.scrollTo(0, 0)
        setanimation('fadein2')
        setTimeout(() => {
            setanimation('')
        }, 1000);
    }, [slug])
    return (
        <div>

            {loading && !data ?
                <div className='min-h-[50vh] flex ' >
                    <Spinner className='m-auto ' />
                </div> : <>
                    {data &&
                        <div id='particularCoursePage' className={`${animation} `} >
                            {console.log(data, "course")}

                            <Helmet>
                                <title>{data.MetaTitle} </title>
                                <meta name='description' content={data.MetaDescription} />
                                <link rel="canonical" href={`${domain}/${data.slug}`} />

                            </Helmet>
                            <h1 className='d-none '>{data.H1_Tag} </h1>
                            <h2 className='d-none'>{data.H2_Tag} </h2>
                            <HomeBanner grd={data.Gradent_Color} image={data.banner_img} page='course' />
                            <CourseDetails data={data} />

                            <WhyChoose />
                            <JourneyCourse />
                            <CertificateSliders />
                            <CoursePlan />
                            <OtherCourses />
                            <CourseReview />
                            <CourseTestimonial />
                            <img src={require('../Assest/companies.png')} alt='Companies' loading='lazy' />
                            <FAQ data={data} />
                            <BecomeInstructor />
                            <Tags data={data} />
                        </div>
                    }
                </>
            }
            {/* <Footer /> */}
        </div>
    )
}

export default ParticularCourse