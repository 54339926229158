import React, { useState } from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import ArrowDown from '../Icons/ArrowDown'
import NavDrop from './NavDrop'
import SearchIcon from '../Icons/SearchIcon'

const NavBar = () => {
    let navigate = useNavigate()
    let [showDrop, setDrop] = useState(false)
    let [showVentures, setShowVentures] = useState('')
    return (
        <div className='min-h-[10vh] z-[100] sticky top-0 navbg  w-full '>
            <Navbar key='lg' expand='lg' className="min-h-[10vh] mx-auto items-center inter">
                <Container fluid className=''>
                    <div className='flex items-center '>
                        <Navbar.Brand href="/">
                            <img loading='lazy' src={require('../Assest/slaWhiteLogo.png')}
                                className='w-[120px] ' alt="Logo" />
                        </Navbar.Brand>

                    </div>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} className='bg-white opacity-85 ' />
                    <Navbar.Offcanvas className=''
                        id={`offcanvasNavbar-expand-lg`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                        placement="start">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                <a href="/"> <img loading='lazy' src={require('../Assest/SLA_logo_Blue.png')}
                                    width={120} alt="Logo" /></a>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="lg:justify-between xl:w-[80vw] text-white lg:items-center  ms-auto pe-3">
                                {/* <Nav.Link onClick={()=>navigate('/')}>Home</span> </Nav.Link> */}
                                <div className='relative mx-2 text-slate-800 mx-xl-4 mt-2'>
                                    <button onClick={() => setDrop(!showDrop)}
                                        className='d-none d-lg-flex text-white flex h-fit items-center gap-2 
                                rounded border-2 border-slate-500 p-[7px] px-3 '>
                                        Courses <ArrowDown />
                                    </button>
                                    {
                                        showDrop && <NavDrop setshow={setDrop} />
                                    }
                                </div>
                                <Nav.Link href="/why-skilllearningacademy" className={` mt-2 lg:text-center `}>
                                    <span className='lg:text-slate-50 text-slate-950'>
                                        Why SLA?  </span>
                                </Nav.Link>
                                <Nav.Link href="/corporate-training" className={` mt-2 lg:text-center `} >
                                    <span className='lg:text-slate-50 text-slate-950'>Corporate Training</span>
                                </Nav.Link>
                                <Nav.Link href="/allCourse" className={` mt-2 lg:text-center d-lg-none `} >
                                    <span className='lg:text-slate-50 text-slate-950'> Courses</span>
                                </Nav.Link>
                                <Nav.Link href="/blogs" className={` mt-2 lg:text-center  `} >
                                    <span className='lg:text-slate-50 text-slate-950'>Blogs</span>
                                </Nav.Link>
                                <Nav.Link href="/events" className={` mt-2 lg:text-center `} >
                                    <span className='lg:text-slate-50 text-slate-950'>Events </span>
                                </Nav.Link>

                                {/* <Nav.Link
                                    onClick={() => setShowVentures((prev) => prev == 'venture' ? '' : 'venture')}
                                    className={` relative mt-2 lg:text-center `} >
                                    <span className='lg:text-slate-50 flex items-center gap-1 text-slate-950'>Our Venture <ArrowDown size={13} /> </span>
                                    {showVentures == 'venture' &&
                                        <div onMouseLeave={() => setShowVentures(false)}
                                            className=' lg:absolute min-w-[200px] text-sm top-16 text-start rounded p-2 min-h-[100px] lg:bg-slate-50    ' >

                                            <button onClick={() => window.open("https://www.meridatechminds.com", '_blank')}
                                                className='block text-slate-800 my-2 text-start border-s-2 border-violet-800 ps-2
                                         hover:scale-[1.02] duration-300 ' >Merida Tech Minds </button>
                                            <button onClick={() => window.open("https://www.fortunetradingacademy.com/", '_blank')}
                                                className='block text-slate-800 my-2 text-start border-s-2 border-violet-800 ps-2
                                         hover:scale-[1.02] duration-300 ' >Fortune Trading Academy </button>
                                            <button onClick={() => window.open("https://www.meridahr.com/", '_blank')}
                                                className='block text-slate-800 my-2 text-start border-s-2 border-violet-800 ps-2
                                         hover:scale-[1.02] duration-300 ' >HR Consulting </button>
                                        </div>}
                                </Nav.Link> */}
                                <Nav.Link onClick={() => setShowVentures((prev) => prev == 'program' ? '' : 'program')} className={` mt-2 lg:text-center relative `} >
                                    <span className='lg:text-slate-50 flex items-center gap-2 text-slate-950'>Programs <ArrowDown size={13} /> </span>
                                    {showVentures == 'program' &&
                                        <div onMouseLeave={() => setShowVentures(false)}
                                            className=' lg:absolute min-w-[200px] text-sm top-16 text-start rounded p-2 min-h-[100px] lg:bg-slate-50    ' >
                                            <button onClick={() => navigate('/certificateCourse') }
                                                className='block text-slate-800 my-2 text-start border-s-2 border-violet-800 ps-2
                                         hover:scale-[1.02] duration-300 ' > Certification Course </button>
                                            <button onClick={() => navigate('/on-job-traing')}
                                                className='block text-slate-800 my-2 text-start border-s-2 border-violet-800 ps-2
                                         hover:scale-[1.02] duration-300 ' >On-Job Training (OJT)  </button>
                                            <button onClick={() => navigate('/internship-traning')}
                                                className='block text-slate-800 my-2 text-start border-s-2 border-violet-800 ps-2
                                         hover:scale-[1.02] duration-300 ' > Internship Traning </button>
                                            <button onClick={() => navigate('/traning-come-recruitment')}
                                                className='block text-slate-800 my-2 text-start border-s-2 border-violet-800 ps-2
                                         hover:scale-[1.02] duration-300 ' > Training Come Recruitment</button>

                                            <button onClick={() => navigate('/inplant-training')}
                                                className='block text-slate-800 my-2 text-start border-s-2 border-violet-800 ps-2
                                         hover:scale-[1.02] duration-300 ' >Inplant Training (IPT) </button>
                                            <button onClick={() => navigate('/industrial-visit')}
                                                className='block text-slate-800 my-2 text-start border-s-2 border-violet-800 ps-2
                                         hover:scale-[1.02] duration-300 ' > Industrial Visit (IV) </button>
                                            <button onClick={() => navigate('/interview-skills')}
                                                className='block text-slate-800 my-2 text-start border-s-2 border-violet-800 ps-2
                                         hover:scale-[1.02] duration-300 ' > Interview Skills </button>
                                        </div>}
                                </Nav.Link>

                                {/* <Nav.Link href="https://crmclient.skilllearningacademy.com" target='_blank' className={` mt-2 lg:text-center `} >
                                    <span className='lg:text-slate-50 text-slate-950'>Login </span>
                                </Nav.Link> */}
                                {/* <Nav.Link href="/events" className={` mt-2 lg:text-center `} >
                                    <span className='lg:text-slate-50 text-slate-950'>Apply as Mentor </span>
                                </Nav.Link> */}
                                <Nav.Link onClick={() => navigate('/contact-us')}
                                    className='d-lg-none mt-2' href="/contact-us">
                                    <span className='lg:text-slate-50 text-slate-950'>Contact</span>
                                </Nav.Link>
                                <button onClick={() => { navigate('/contact-us') }}
                                    className=' d-none btnbg mt-2 d-lg-block w-fit mx-3 p-[11px] 
                                    px-3 rounded-full fw-semibold '>
                                    Contact Us
                                </button>
                                {/* <button className='mx-3 mt-2 d-none d-lg-block'>
                                    <SearchIcon />
                                </button> */}
                            </Nav>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>


                </Container >
            </Navbar >


        </div >
    )
}

export default NavBar