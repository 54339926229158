import React from 'react';

const SliderRight = ({ size, className, style, onClick }) => {
    return (
        <div className={`tclr absolute p-2 rounded-full bg-violet-300 text-violet-600 -right-3 transform -translate-y-1/2 top-[50%] z-10 cursor-pointer`} onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={size ? size : "16"}
                height={size ? size : "16"}
                fill="currentColor"
                viewBox="0 0 16 16"
               // Custom styles applied directly to the SVG element
            >
                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
            </svg>
        </div>
    );
};

export default SliderRight;
