import React from 'react'

const LikeIcon = ({ size }) => {
    return (
        <svg width={size ? size : "52"} height={size ? size : "52"} viewBox="0 0 52 51" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M25.6417 0.117188C11.6176 0.117188 0.25 11.487 0.25 25.5089C0.25 39.5329 11.6176 50.9006 25.6417 50.9006C39.6636 50.9006 51.0334 39.5308 51.0334 25.5089C51.0334 11.487 39.6636 0.117188 25.6417 0.117188ZM19.4212 32.3273C19.4212 33.6996 18.3092 34.8095 16.939 34.8095C15.5667 34.8095 14.4569 33.6996 14.4569 32.3273V21.7448C14.4569 20.3725 15.5667 19.2604 16.939 19.2604C18.3092 19.2604 19.4212 20.3725 19.4212 21.7448V32.3273ZM36.9556 32.1488C36.9556 34.0889 35.822 34.7837 33.8819 34.7837H24.9555C23.0154 34.7837 21.4431 33.2113 21.4431 31.2712V22.4911C21.4431 22.4911 21.2689 21.0306 22.8863 19.6584C23.8005 18.8819 25.1577 17.3999 26.1063 15.6017C27.9905 12.029 29.1176 10.988 29.9758 11.2676C33.1549 12.2979 31.5653 17.0106 30.5393 18.9787H33.4431C35.3811 18.9787 36.9556 20.551 36.9556 22.4911V32.1488Z"
                fill="currentColor" />
        </svg>

    )
}

export default LikeIcon