import React, { useEffect, useState } from 'react'
import CropCircle2 from '../Component/CourseComponent/CropCircle2'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import Blogs from '../Component/HomeComponent/Blogs'
import EventsNews from '../Component/HomeComponent/EventsNews'

const CertificateCoursePage = () => {
  let [activeCircle, setActiveCircle] = useState(0)
  let data = [
    {
      heading: 'High School Graduates : ',
      para: `Get a head start on your career by gaining industry-recognized certifications that can guide your future educational and professional 
      choices.`
    },
    {
      heading: 'College Students :',
      para: `Enhance your academic experience with the best certification courses that provide practical skills and real-world applications, bridging the gap between theory and practice. `
    },
    {
      heading: 'Aspiring Professionals :  ',
      para: ` Advance your skills and earn credentials in your field to differentiate yourself in the job market and achieve your career goals. `
    },
    {
      heading: 'Career Changers :  ',
      para: `Explore new fields and gain certifications that facilitate a smooth transition into a new career path, providing you with the knowledge and skills needed for success. `
    },
    {
      heading: 'Working Professionals : ',
      para: `Upgrade your skills and earn certifications that can open doors to career advancement and new opportunities within your current field or in a new 
      industry.`
    }, {
      heading: 'Educators and Trainers :',
      para: `Incorporate our certification programs into your curriculum or professional development plans, providing students with valuable industry-recognized credentials. `
    }, {
      heading: 'Tech Enthusiasts  : ',
      para: `Dive into the latest technologies and gain certifications that validate your expertise, enhancing your understanding and application of cutting-edge 
      innovations.`
    },
  ]
  let whyData = [
    {
      heading: 'Industry-Recognized Certifications : ',
      para: `Enhance your resume with credentials that showcase your expertise and set you apart in the job market.`
    },
    {
      heading: 'Practical Experience  :',
      para: `Engage in real-world projects and scenarios that provide hands-on learning and prepare you for industry challenges. `
    },
    {
      heading: 'Expand Your Professional Network :  ',
      para: ` Connect with industry experts and peers to unlock opportunities for collaboration and advance your career. `
    },
    {
      heading: 'Develop Both Technical and Soft Skills :  ',
      para: `Build proficiency in your field while honing essential soft skills like communication and problem-solving. `
    },
    {
      heading: 'Job-Ready Status : ',
      para: `Be prepared for the professional world with practical experience and certifications that demonstrate your capabilities.`
    },
    {
      heading: 'Flexible Learning Experience :',
      para: `Benefit from our Hybrid Classes that offer the flexibility of online learning combined with the structure of in-person sessions. `
    }, {
      heading: 'Capstone Projects  : ',
      para: `Showcase your skills with projects that reflect real-world applications, enhancing your portfolio and job readiness.`
    },
  ]
  let sixPoints = [
    {
      heading: 'Initial Assessment',
      short: '1: Assessment',
      para: `We begin with a brief assessment to understand your needs and help you choose the right technology. Our expert counselors guide you in 
      selecting the best course tailored to your goals.`
    },
    {
      heading: 'Expert Training',
      short: '2: Training ',
      para: `Gain theoretical knowledge from industry experts through our well-structured training programs. Learn from professionals who share real-world insights 
      and expertise.`
    },
    {
      heading: 'Hands-On Experience',
      short: '3: Experience  ',
      para: `Apply what you learn with practical training that mirrors real-life scenarios. Our hands-on approach ensures you're job-ready from day one, bridging
       the gap between theory and practice.`
    },
    {
      heading: 'Essential Soft Skills ',
      short: '4: Soft Skills ',
      para: `Develop crucial soft skills like communication and problem-solving. We focus on these skills to prepare you for success in any
       professional setting.`
    },
    {
      heading: 'Final Assessment',
      short: '5: Assessment',
      para: `At the end of the course, undergo a final assessment to evaluate your progress and readiness. 
      This ensures you have mastered the skills needed to succeed in your field.`
    },
    {
      heading: 'Certification',
      short: '6: Certification',
      para: `Earn a recognized certification upon successful completion of the course. 
      This credential demonstrates your skills and readiness to potential employers.`
    },
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='min-h-[50vh] bgsec' >
      <> <Helmet>
        <title>Latest Tech News & Insights - Read Our Blog | TechTalk </title>
        <meta name="description" content="Get insightful content on all the courses and more expert tips, latest trends full of Knowledge, Inspiration and software training updates and informations." />
        <link rel="canonical" href={`${domain}/certificateCourse`} />
      </Helmet>
        <h1 className='d-none'>informative blog content </h1>
        <h2 className='d-none'>best blogging tips and tricks </h2>
      </>
      <img src={require('../Assest/whyslaBanner.webp')} className='w-full ' alt="WhySla" />
      {/* Content */}
      <section className='relative w-full mt-10 my-3 sm:mt-0 pt-8 sm:pt-16 poppins'>
        <h6 style={{ zIndex: 0 }}
          className='text-5xl sm:text-7xl xl:text-8xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
          Career Growth
        </h6>
        <div style={{ zIndex: 6 }} className='relative '>
          <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
            Explore Your Certification Journey
          </h4>
          <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
            Clear Path
            <img src={require('../Assest/arrows.png')} alt="Arrow"
              className='w-[50px]' />
          </h6>
        </div>
      </section>
      <main className='container my-5 ' >

        <div className='col-lg-8 col-xl-8 mx-auto p-4 flex' >
          <CropCircle2 activeCircle={activeCircle} scroll='certificatecourse'
            setActiveCircle={setActiveCircle} whyChooseData={sixPoints} />
        </div>
        <div id='certificatecourse' className=' my-10 rounded contentbg p-3 poppins' >
          <h5 className='text-violet-800 ' > {sixPoints[activeCircle].heading} </h5>
          <p className='my-2 text-sm' > {sixPoints[activeCircle].para}  </p>

        </div>
        {/* Overview */}

        <div className='rounded my-3 bg-white  p-2 p-sm-5 '>
          <section>
            <h5 className='text-violet-800 text-3xl fw-semibold ' >
              Certification Course </h5>
            <p> At Skill Learning Academy, our Certification courses are designed to take you from a beginner to an expert in various fields, offering practical experience that matches industry needs. Whether you’re into web development, cloud computing, digital marketing, or any other areas, our programs are your gateway to reaching your career
              goals. </p>
            <p> You’ll dive into real-world projects and scenarios that prepare you for the job market. The benefits of certfication courses go beyond technical skills. They help you with enhancing communication and problem-solving, setting you up for success in any professional
              setting.</p>
            <p>For those looking for flexibility, our Hybrid Classes combine the best of online and in-person learning, allowing you to balance your education with your personal schedule. With less duration than traditional programs, our courses are designed to fit your busy life while still providing the depth of knowledge you
              need. </p>
            <p>Our Capstone Projects let you apply what you’ve learned in practical, real-life situations, helping you build a standout portfolio for potential employers.
              When you join SkillLearningAcademy, you become part of a lively community of learners and experts. You’ll get valuable resources, network with peers, and gain insights that can boost your career.
            </p>
          </section>
          <div className=' '>
            <h5 className='text-violet-800 fw-semibold text-3xl' >Who is it for? </h5>
            {
              [...data].map((x) => (
                <div>
                  <h6 className='text-violet-800'>{x.heading} </h6>
                  <p>{x.para} </p>
                </div>
              ))
            }
          </div>
          <div className=' '>
            <h5 className='text-violet-800 fw-semibold text-3xl ' >Why choose our Certification courses? </h5>
            {
              [...whyData].map((x) => (
                <div>
                  <h6 className='text-violet-800'>{x.heading} </h6>
                  <p>{x.para} </p>
                </div>
              ))
            }
          </div>
        </div>
      </main>
      <EventsNews />
      <Blogs />
    </div>
  )
}

export default CertificateCoursePage