import React, { useEffect, useState } from 'react'

const Tags = ({ data }) => {
    let [tags, setTag] = useState()
    console.log(data.Tags);
    useEffect(() => {
        if (data && data.Tags)
            setTag(data.Tags.map((obj) => obj.point))

    }, [data.id])

    return (
        <div className='container my-3 ' >
            <div className='flex gap-3 ' >

                <h5 className='text-3xl fw-semibold ' >Tags </h5>
                <div className='opacity-100 border-2 h-fit border-t-2 w-full relative 
                top-4 border-opacity-40 border-violet-700 ' ></div>
            </div>
            {
                tags && tags.map((val) => (
                    <button className='p-2 rounded bg-slate-50 mx-1 px-3 text-violet-700  ' >{val} </button>
                ))
            }
        </div>
    )
}

export default Tags