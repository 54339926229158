import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { domain, hostname } from '../App'
import { Helmet } from 'react-helmet'
import RightSideBlog from '../Component/RightSideBlog'
import CommentIcon from '../Icons/CommentIcon'
import ClockIcon from '../Icons/ClockIcon'
import CalenderIcon from '../Icons/CalenderIcon'

const ParticularEvent = () => {
    let { slug } = useParams()
    function convertDate(dateStr) {
        // Create a new Date object from the date string
        const date = new Date(dateStr);
        // Define an array of month names
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        // Get the day of the month (1-31)
        const day = date.getDate();
        // Get the month name from the months array (0-11)
        const month = months[date.getMonth()];

        // Get the full year (YYYY)
        const year = date.getFullYear();

        // Return the formatted date string
        return `${month} ${day}, ${year}`;
    }
    let [data, setEvent] = useState()
    useEffect(() => {
        if (slug) {
            axios.get(`${hostname}/event/SlugEvent/${slug}/`).then((response) => {
                console.log(response.data);
                setEvent(response.data)
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [slug])
    function convertTimeFormat(timeString) {
        // Split the time string into its components
        const [hours, minutes] = timeString.split(':');

        // Convert the hours component to a number
        let hoursNumber = parseInt(hours);

        // Determine the period (AM/PM)
        const period = hoursNumber >= 12 ? 'PM' : 'AM';

        // Convert to 12-hour format
        hoursNumber = hoursNumber % 12 || 12;

        // Return the formatted time
        return `${hoursNumber}:${minutes} ${period}`;
    }

    return (
        <div>
            {data && <> <Helmet>
                <title> {data.heading} </title>
                <meta name="description" content={`${data.heading}, Event By Merida Skill Learning Academy `} />
                <link rel="canonical" href={`${domain}/events/${slug}`} />
            </Helmet>
                <h1 className='d-none'> Events Held by Merida tech Minds</h1>
                <h2 className='d-none'>  </h2>
            </>}
            <section className='row container mx-auto my-5'>
                <div className='col-lg-8 col-xl-9 '>
                    <section>
                        {data && <>
                            <img loading='lazy' className='rounded w-full object-cover  '
                                src={data.events_img ? data.events_img : require('../Assest/Maskdummyblog.png')} alt="Blog" />
                            <p className='text-sm mt-3 flex items-center gap-2'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                            </svg>Event {data.status} </p>
                            <h4 className=''>{data.heading && data.heading} </h4>
                            <h6 className='my-3 text-slate-600 fw-normal'> Event By Merida Skill Learning Academy </h6>
                            <p className='flex items-center fw-semibold gap-2'> <span className='text-violet-600'><CalenderIcon /> </span>
                                {data.ending_date ? "Starting Date :" : "Event date "} <span className='text-slate-500'> {convertDate(data.starting_date)}</span> </p>
                            {data.ending_date && <p className='flex items-center fw-semibold gap-2'> <span className='text-violet-600'><CalenderIcon /> </span> Ending Date :
                                <span className='text-slate-500'>  {convertDate(data.ending_date)} </span> </p>}
                            <p className='flex items-center fw-semibold gap-2'> <span className='text-violet-600' ><ClockIcon /> </span> Timing :
                                <span className='text-slate-500'>{convertTimeFormat(data.starting_time)} - {convertTimeFormat(data.ending_time)} </span> </p>
                            <p className='flex items-center fw-semibold gap-2'>
                                <span className='text-violet-600'> <CommentIcon /></span> Event Link :
                                <a className='' href={data.eventLink} target='_blank' >{data.eventLink} </a>
                            </p>

                            {/* Tet content */}
                            {data.content && data.content.map((obj) => {
                                return (
                                    <section>
                                        <h4 className={` ${obj.paragraph == 'null' && 'text-2xl fw-bold'}  `}>{obj.heading != 'null' && obj.heading} </h4>
                                        <p>{obj.paragraph != 'null' && obj.paragraph} </p>
                                    </section>
                                )
                            })}

                        </>}

                    </section>

                    {/* <BlogComment /> */}
                </div>
                <div className='col-lg-4 col-xl-3 '>
                    <RightSideBlog />
                </div>
            </section>
        </div>
    )
}

export default ParticularEvent