import React, { useState } from 'react'
import PinkTick from '../../Icons/PinkTick'
import { useNavigate } from 'react-router-dom'

const CoursePlan = () => {
    let [coursecard, setcoursecard] = useState(4)
    let navigate = useNavigate()
    let plans = [
        {
            title: 'Certificate Course',
            points: ['Certification (.pvt , LTD)', 'Capstone projects', 'Less Duration', 'Hybrid classes'],

            navigate: '/certificateCourse'
        },
        {
            title: 'On-Job Training (OJT)',
            points: ['Train & Hire', 'Conditional Letter (CTC 2-6 LPA)', 'Real Time Project', 'Dual certification', 'Hybrid classes'],
            navigate: '/on-job-traing'

        },
        {
            title: 'Internship Traning',
            points: ['Certification', 'Real Time Projects', 'Variable Duration (Students)', 'Hybrid classes'],
            navigate: '/internship-traning'

        },
        {
            title: 'Training Come Recruitment',
            points: ['Train & Recruit', 'Internship', 'Dual certification', 'Hybrid classes'],
            navigate: '/traning-come-recruitment'

        }

    ]
    return (
        <div className=' py-4 bgsec xl:min-h-[88vh] relative ' >
            <img src={require('../../Assest/Shape2.png')} className='absolute right-0 w-20 sm:w-28 xl:w-32 ' alt="" />
            <section className='relative w-full mt-10 sm:mt-0 pt-8 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-9xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    Programs
                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                        Explore Our Range of Offerings
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
                        Find Your Fit
                        <img src={require('../../Assest/arrows.png')} alt="Arrow"
                            className='w-[50px]' />
                    </h6>
                </div>
            </section>
            {/* Plans */}
            <main className='container-fluid rounded-3xl bg-blue-200 bg-opacity-50 p-3 ' >
                <section className='bg-slate-100 rounded-3xl p-3 row container mx-auto ' >
                    {
                        plans.map((obj, index) => (

                            < div className={` col-md-6 col-lg-3 p-3 `} >
                                <article onMouseEnter={() => setcoursecard(index + 1)}
                                    onMouseLeave={() => setcoursecard(4)}
                                    className={`p-3 h-[400px] cursor-pointer justify-between duration-700
                         ${coursecard == index + 1 && 'text-white bg-violet-600 scale-[1.05] shadow-md shadow-violet-600 '} rounded-xl flex flex-col `} >

                                    <h5 className='fw-semibold ' >{obj.title} </h5>
                                    <div className={` ${coursecard == index + 1 && "text-slate-50 "} text-slate-500 text-sm`} >
                                        <p className={` text-sm ${coursecard == index + 1 && "text-slate-50 "} `}>Unleash the power of automation </p>


                                        {
                                            obj.points.map((val) => (
                                                <li className={` flex gap-3 my-2 ${coursecard == index + 1 && " text-slate-50 "} `} >
                                                    <span className={` ${coursecard == index + 1 && "bg-violet-400 text-slate-50 "} h-fit rounded-full text-pink-600 bg-slate-200 p-[4px] `} >
                                                        <PinkTick /> </span>
                                                    {val}
                                                </li>
                                            ))
                                        }


                                    </div>
                                    <button className={`w-full rounded-full duration-700
                                 ${coursecard == index + 1 ? "bg-slate-50 text-slate-950 " :
                                            'bg-slate-400 fw-semibold text-slate-50 '} p-2  `} >
                                        Choose plan
                                    </button>

                                </article>
                            </div>
                        ))
                    }


                </section>

            </main>
        </div >
    )
}

export default CoursePlan