import axios from 'axios'
import React, { useState } from 'react'
import { hostname } from '../../App'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'

const EnquiryForm = () => {
    let [enquiryForm, setEnquiryForm] = useState({
        first_name: '',
        email: '',
        phone: '',
        message: '',
        captcha: false
    })
    let handleChange = (e) => {
        let { name, value } = e.target
        if (name == 'phone' && value.length > 10) {
            value = enquiryForm.phone
        }
        setEnquiryForm((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let [loading, setLoading] = useState(false)
    let handleContact = () => {
        if (enquiryForm.email != '' && enquiryForm.phone != ''
            && enquiryForm.name != ''
            && enquiryForm.message != '' && enquiryForm.captcha == true) {
            setLoading(true)
            document.getElementById('errormsg').innerHTML = ''
            axios.post(`${hostname}/blog/ContactsInformation/`, enquiryForm).then((response) => {
                console.log(response.data);
                toast.success('Your Message has been sended, Our team will get touch in short time.')
                setLoading(false)
                setEnquiryForm({
                    first_name: '',
                    email: '',
                    phone: '',
                    message: '',
                    captcha: false
                })
            }).catch((error) => {
                console.log(error);
                document.getElementById('errormsg').innerHTML = 'Error acquired'
                setLoading(false)
            })
        }
        else {
            document.getElementById('errormsg').innerHTML = 'Enter all the fields.*'

        }
    }
    return (
        <div className='text-white text-sm poppins ' >
            <h4 className='text-center fw-semibold'> Enquiry Form</h4>
            {/* <img src={require('../../Assest/slaWhiteLogo.png')} alt="Sla white Logo"
                className='mx-auto w-[150px] mb-3 ' /> */}
            <input onChange={handleChange} name='first_name' value={enquiryForm.first_name} type="text"
                className='p-2 w-full px-3 outline-none rounded-xl my-2 bg-transparent inputbg  border-2 border-violet-400 border-opacity-50 ' placeholder='Name *' />
            <input onChange={handleChange} name='email' value={enquiryForm.email} type="email" className='p-2 w-full px-3 outline-none rounded-xl my-2 bg-transparent inputbg  border-2 border-violet-400 border-opacity-50 ' placeholder='Email *' />
            <input onChange={handleChange} name='phone' value={enquiryForm.phone} type="number" className='p-2 w-full px-3 outline-none rounded-xl my-2 bg-transparent inputbg  border-2 border-violet-400 border-opacity-50 ' placeholder='Phone number * ' />
            <textarea rows={3} onChange={handleChange} name='message' value={enquiryForm.message} type="text"
                className='p-2 w-full px-3 outline-none rounded-xl my-2 bg-transparent inputbg  border-2 border-violet-400 border-opacity-50 ' placeholder='Message *' />
            <div className='p-2 flex items-center justify-center'>
                <ReCAPTCHA
                    className=' md:scale-[0.80] inputbg '
                    spellCheck={enquiryForm.captcha}
                    sitekey="6Ledg_QpAAAAAKgVH6WcItGafyMHUtoShRHFmMVc"
                    onChange={() => {
                        setEnquiryForm((prev) => ({
                            ...prev,
                            captcha: !enquiryForm.captcha
                        }))
                    }}
                />
            </div>
            <p className='text-slate-50 text-center h-[15px]' id='errormsg' > </p>

            <button onClick={handleContact} disabled={loading}
                className='w-full p-3 text-white bg-violet-800 rounded-xl '>
                {loading ? "loading..." : "Contact Us  "}</button>

        </div>
    )
}

export default EnquiryForm