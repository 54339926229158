import React from 'react'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import RightSideBlog from '../Component/RightSideBlog'

const CorporateTraining = () => {
    return (
        <div>
            <> <Helmet>
                <title>Learn Corporate Training Program from Best Trainer </title>
                <meta name="description" content="Enroll the corporate training course to learn team's performance tailored programs in leadership, communication and teamwork to boost productivity and
                 sucess. " />
                <link rel="canonical" href={`${domain}/corporate-training`} />
            </Helmet>
                <h1 className='d-none'>Corporate Training Programme</h1>
                <h2 className='d-none'>Best Corporate Training Course </h2>
            </>
            <main className='row my-4 container mx-auto'>

                <section className='col-lg-8 '>
                    <div className='text-lg '>
                        <img src={require('../Assest/corporateTrainingBanner.jpg')} loading='lazy' className=' w-full rounded object-cover ' alt="Corporate training" />
                        <h3 className=' my-4 fw-semibold '> Corporate training </h3>
                        <p> Corporate training offers many benefits for both individuals and organizations.
                            Engaging in corporate training programs provides essential skills that enhance professional conduct and job readiness.
                            It is crucial for anyone looking to advance in their career or improve workplace performance. </p>
                        <p>
                            A well-structured corporate training program equips employees with the knowledge to excel in areas such as workplace
                            communication skills, corporate etiquette, and effective employee engagement strategies. These programs are designed to address common challenges, resulting in a more cohesive and efficient work environment.
                        </p>
                        <p>
                            Participate in our job readiness classes to significantly boost your confidence and competence in the workplace. Such training helps individuals adopt best practices, ensuring they are well-prepared for the demands of their roles. Moreover, it provides practical tools for improving workplace culture, which can lead to
                            increased job satisfaction and productivity.
                        </p>
                        <p>
                            Choosing the right corporate training near you is crucial. SkillLearninigAcademy’s Corporate Training ensures that the training is relevant and tailored to the specific needs of your industry and work environment. It not only enhances individual skills but also contributes to overall organizational success, making it a valuable
                            component of professional development.
                        </p>
                        {/* Who */}
                        <h6 className=' my-4 text-2xl fw-semibold '>
                            Who is it for?
                        </h6>
                        <div>
                            <p className='text-xl fw-semibold ' > Professionals Seeking Career Advancement </p>
                            <p>  Designed for individuals aiming to advance in their careers by enhancing their skill sets and embracing corporate best practices. </p>
                        </div>
                        <div>
                            <p className='text-xl fw-semibold ' >Managers and Team Leaders </p>
                            <p>  Perfect for those looking to implement effective Employee Engagement Strategies and improve workplace communication skills. </p>
                        </div>
                        <div>
                            <p className='text-xl fw-semibold ' > New Hires and Entry-Level Employees </p>
                            <p> Ideal for employees who want to accelerate their job readiness through job readiness classes and master corporate etiquettes.
                            </p>
                        </div>
                        <div>
                            <p className='text-xl fw-semibold ' >Organizations Focused on Improving Workplace Culture </p>
                            <p> Tailored for companies striving to boost employee engagement and adopt ways to improve work performance within their teams. </p>
                        </div>
                        {/* WHY */}
                        <h6 className=' my-4 text-2xl fw-semibold '>
                            Why choose our Corporate Training?
                        </h6>
                        <div>
                            <p className='text-xl fw-semibold ' > Interactive Learning Experience </p>
                            <p> Our program offers engaging sessions that dive into Employee Engagement Strategies, making sure participants are fully prepared for success on the job.

                            </p>
                        </div>
                        <div>
                            <p className='text-xl fw-semibold ' >Flexible and Accessible </p>
                            <p> Learn at your own pace with our flexible modules that fit your schedule, whether you're searching for corporate training near me or engaging in remote learning.
                            </p>
                        </div>
                        <div>
                            <p className='text-xl fw-semibold ' > Tailored for Modern Workplaces </p>
                            <p> Stay ahead in the competitive environment with our training that aligns with the best corporate training programs and corporate best practices.
                            </p>
                        </div>
                        <div>
                            <p className='text-xl fw-semibold ' > Real-World Applications </p>
                            <p>Our hands-on approach ensures you gain practical experience, making it easier to implement ways to improve work performance and advance in your career.
                            </p>
                        </div>
                        <div>
                            <p className='text-xl fw-semibold ' > Expert Guidance </p>
                            <p>Benefit from insights provided by industry professionals who specialize in improving workplace culture and driving career advancement tips.
                            </p>
                        </div>
                    </div>
                </section>
                <section className='col-lg-4  '>
                    <RightSideBlog />
                </section>
            </main>
        </div>
    )
}

export default CorporateTraining