import React from 'react'

const 
RightArrow = ({ size  }) => {
    return (
        <svg width={size ? size : "19"} height={size ? size : "12"} viewBox="0 0 19 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.09766 6.36682H16.1766" stroke="#F6FF56" stroke-width="1.666" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.3896 2.9655L16.7911 6.36692L13.3896 9.76834" stroke="#F6FF56" stroke-width="1.666" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default RightArrow