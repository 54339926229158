import React, { useState } from 'react'
import MailIcon from '../../Icons/MailIcon'
import WhatsappIcon from '../../Icons/WhatsappIcon'
import FacebookIcon from '../../Icons/FacebookIcon'
import TwitterIcon from '../../Icons/TwitterIcon'
import EnquiryForm from './EnquiryForm'
import ContactModel from './ContactModel'
import LevelIcon from '../../Icons/LevelIcon'
import Clock2Icon from '../../Icons/Clock2Icon'
import BookIcon from '../../Icons/BookIcon'
import Certificate from '../../Icons/Certificate'
import Cap from '../../Icons/Cap'
import ClockIcon from '../../Icons/ClockIcon'
import RightSideBlog from '../RightSideBlog'
import LatestPost from '../Blog/LatestPost'
import RecentEvents from '../RecentEvents'

const SideForm = ({ data }) => {
  let [show, setshow] = useState(false)
  let [course, setCourse] = useState()

  return (
    <div className='w-full ' >
      {/* Form */}
      <div className='rounded-xl contactcoursebg shadow border-1 
      mb-3 border-2 border-violet-400 p-[20px] 
     border-opacity-75 '>
        <EnquiryForm />
      </div>
      {/* Card 1 */}
      <div className='rounded-xl shadow border-1 bg-white p-[10px]
       border-slate-500 border-opacity-35 '>
        <button onClick={() => setshow(true)}
          className='uppercase flex gap-2 justify-center items-center grd
           bclr1 p-2 bg-violet-800 my-2
           fw-semibold w-full rounded text-white border-2 '>
          Download Brochure
        </button>


      </div>
      <RecentEvents />
      <LatestPost />

      {/* <div className='bg-violet-500 rounded-xl shadow mb-3 p-4 text-white '>
          <h4 className=' text-xl'>Industry Oriented Curriculum </h4>
          <div className='h-[100px] overflow-hidden '>
            <p className='text-xs'>
              {data && data.PreReq && data.PreReq.map((obj) => obj.point + ', ')}
            </p>
          </div>
        </div> */}
      {/* <h4> Course includes : </h4>
        <div className='border-b-2 px-1 mt-3 py-[6px] items-center flex justify-between '>
          <div className='flex gap-2 items-center'>
            <LevelIcon />
            Level </div>
          <p className='text-slate-500 text-opacity-80  m-0'>Expert </p>
        </div>
        <div className='border-b-2 px-1 mt-3  py-[6px] flex justify-between '>
          <div className='flex gap-2 items-center'>
            <ClockIcon />
            Duration </div>
          <p className='text-slate-500 text-opacity-80  m-0'> 11h 20m </p>
        </div>
        <div className='border-b-2 px-1 mt-3 py-[6px] flex justify-between '>
          <div className='flex gap-2 items-center'>
            <BookIcon />
            Lessons </div>
          <p className='text-slate-500 text-opacity-80 m-0 '>12 </p>
        </div>
        
        <div className='border-b-2 px-1 mt-3 py-[6px] flex justify-between '>
          <div className='flex gap-2 items-center'>
            <Certificate />
            Certifications </div>
          <p className='text-slate-500 text-opacity-80 m-0 '> Yes </p>
        </div>
        <div className='border-b-2 px-1 mt-3 py-[6px] flex justify-between '>
          <div className='flex gap-2 items-center'>
            <Cap />
            Earn Upto </div>
          <p className='text-slate-500 text-opacity-80 m-0 '> 25K </p>
        </div> */}


      {
        <ContactModel show={show} setShow={setshow}
        // courseName={course.title}
        />}
    </div>
  )
}

export default SideForm