import React from 'react'
import RightArrow from '../Icons/RightArrow'
import { useNavigate } from 'react-router-dom';

const CourseCard = ({ name, page, obj }) => {
    console.log(obj, 'card');
    let navigate = useNavigate()
    return (
        <div className={`${page == 'otherCourse' ? '' : ' w-[16rem]'} 
        max-w-[16rem] mx-auto rounded border-t-8 bgclr p-3 bg-opacity-85 border-blue-950 relative `} >
            <img src={require('../Assest/checkPattern.webp')} alt="Check"
                className='absolute w-full h-full object-contain top-0 left-0 ' />
            <img src={obj.img ? obj.img : require('../Assest/javalogo.png')}
                className='my-4 w-[90px] relative' alt="Course Logo" />
            <h4 className='text-white text-break break-words h-[100px] flex items-center '>{obj && obj.Title} </h4>
            <button onClick={() => navigate(`/${obj && obj.slug}`)} className='relative text-yellow-200 flex gap-2 items-center mx-3 mb-2 hover:scale-[1.04] duration-500 fw-semibold ' >
                Check Now <RightArrow />
            </button>
        </div>

    )
}

export default CourseCard