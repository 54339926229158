import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

const SubjectSlides = ({ data, name }) => {
    let settings = {
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    let navigate = useNavigate()
    return (
        <div className='my-1'>
            {name == "blog" && <Slider {...settings} className='py-2'>
                {
                    data && data.map((x, index) => {
                        return (
                            <div onClick={() => navigate(`/blogs/${x.slug}`)} className='p-3 bg-white cursor-pointer hover:scale-[1.01] transition duration-500 rounded shadow-sm border-1 border-slate-200 mt-2 my-2'>
                                <img src={x.img} className='rounded-xl h-[20rem] w-full sm:h-[10rem] lg:h-[15rem] object-cover ' loading='lazy' alt="Image" />
                                <p className='text-lg fw-medium line-clamp-1 mt-3' >{x.Main_Title && x.Main_Title.slice(0, 40)}{x.Main_Title && x.Main_Title.length > 40 && "..."} </p>
                                <p className='text-slate-500 line-clamp-2 text-sm '>{x.Paragraph1 && x.Paragraph1.slice(0, 100)}{x.Paragraph1 && x.Paragraph1.length > 100 && "..."} </p>
                            </div>
                        )
                    })
                }
            </Slider>}
            {name == "course" && <Slider {...settings} className='py-2'>
                {
                    data && data.map((x, index) => {
                        return (
                            <div onClick={() => navigate(`/${x.slug}`)} className='p-3 bg-white rounded cursor-pointer hover:scale-[1.01] transition duration-500 shadow-sm border-1 border-slate-200 mt-2 my-2'>
                                <img src={x.img} className='rounded-xl h-[20rem] w-full sm:h-[10rem] lg:h-[15rem] object-cover ' loading='lazy' alt="Image" />
                                <p className='text-lg fw-medium mt-3 line-clamp-1 ' >{x.Title}</p>
                                <p className='text-slate-500 line-clamp-2 text-sm '>{x.MetaDescription} </p>
                            </div>
                        )
                    })
                }
            </Slider>}
        </div>
    )
}

export default SubjectSlides