import React from 'react'
import PinkTick from '../../Icons/PinkTick'
import { useNavigate } from 'react-router-dom'

const DigitalMarketing = () => {
    let navigate = useNavigate()
    return (
        <div className=' xl:min-h-[88vh] py-5 row container mx-auto ' >
            <main className='col-md-6 flex ' >
                <section className='my-auto ' >
                    <img className='w-10/12 ' src={require('../../Assest/digitalmarketing.png')}
                        alt="Digital Marketing" />
                </section>
            </main>
            <main className='col-md-6 flex poppins ' >
                <section className='my-auto ' >
                    <h6 className='text-blue-800 fw-semibold ' >
                        Skill Learning Academy  </h6>
                    <article className='relative w-full pt-4 sm:pt-5 poppins'>
                        <h6 style={{ zIndex: 0 }}
                            className='text-6xl sm:text-7xl xl:text-9xl fw-bold absolute top-0 txtbgz'>
                            About
                        </h6>
                        <div style={{ zIndex: 6 }} className='relative '>
                            <h4 className='text-2xl  xl:text-4xl poppins fw-semibold text-blue-800'>
                              Why choose skill learning & our Best Solutions
                            </h4>
                        </div>
                    </article>
                    <div className='border-s-4 border-blue-800 text-sm px-2 my-4    ' >
                        By understanding the importance of industry endorsed curriculum, skill learning academy supports a conducive learning environment with strong industry connectivity which helps the  student community to achieve
                        project based learning.
                    </div>
                    <div className='border-s-4 border-blue-800 text-sm px-2 my-4    ' >
                        Skill assessments with help of inbuilt portal system, exposure to multiple case studies of project applications of our clients and inhouse HR consulting vertical along with vast job portal, community access gives the ultimate edge for our student community to achieve their
                        career goals.
                    </div>

                    {/* <ul className='my-3 gap-3 text-sm' >
                        <li className='flex gap-2 items-center '>
                            <span className='bg-blue-600 rounded p-[2px] text-white '><PinkTick /> </span>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Saepe, impedit.
                        </li>
                        <li className='flex my-2 gap-2 items-center '>
                            <span className='bg-blue-600 rounded p-[2px] text-white '><PinkTick /> </span>
                            Lorem ipsum dolor sit amet consectetur adipisicing.</li>
                        <li className='flex my-2 gap-2 items-center '>
                            <span className='bg-blue-600 rounded p-[2px] text-white '><PinkTick /> </span>
                            Lorem ipsum dolor sit amet consectetur. </li>
                    </ul> */}
                    <button onClick={() => navigate('/contact-us')} className='bg-blue-600 rounded-full text-white p-2 px-4 my-3 text-sm fw-semibold ' >
                        Contact us
                    </button>
                </section>

            </main>

        </div>
    )
}

export default DigitalMarketing