import React, { useEffect, useRef, useState } from 'react'
import RightArrow from '../../Icons/RightArrow'

const HowItStarts = () => {
    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState([false, false, false, false, false, false, false, false]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        [0, 300, 600, 900, 1200, 1500].map((val, index) => {
                            setTimeout(() => {
                                setIsVisible(Array(index + 1).fill(true))
                            }, val);
                        })
                        observer.disconnect();
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);
    return (
        <div className='relative bg-blue-50 min-h-[88vh] py-3 ' >
            <img className='-top-7 right-0 absolute w-40 ' src={require('../../Assest/moon1.png')} alt="Moon" />
            <img className='top-20 absolute w-40 ' src={require('../../Assest/spring.png')} alt="Spring" />
            <h5 className='text-center text-3xl fw-semibold text-blue-800 poppins'>
                <sup>.</sup> How it works <sup> .</sup> </h5>
            <section className='relative w-full mt-10 sm:mt-0 pt-10 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-8xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    Steps
                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-3xl text-center poppins  fw-semibold text-blue-800'>
                        Skills That Transform  Ordinary  <br /> Careers into Extraordinary Careers.
                    </h4>
                </div>
            </section>
            <p className='text-center text-sm text-slate-500'>
                Gain essential skills through practical experience. Turn your career into something truly exceptional with our training.
            </p>
            {/* Title ending */}

            <main className='row container mx-auto my-5 poppins ' >
                <section className='col-md-6 flex flex-col items-center ' >
                    <div ref={ref}
                        className={`w-[20rem] lg:w-[24rem] my-6 sm:my-16  ${isVisible[0] ? 'fadein' : 'opacity-0 '} `} >
                        <h4 className='text-3xl fw-semibold '>
                            Learn from Basics to Advanced with Practical Simulations </h4>
                        <p className=' text-slate-600 text-sm' >
                            Our courses blend theory with hands-on practice, ensuring you fully grasp both foundational concepts and advanced techniques
                        </p>
                    </div>
                    {/* Section2 */}
                    <article className='relative w-full h-fit my-6 sm:my-16 ' >

                        <div className={` ${isVisible[2] ? 'fadein' : ' opacity-0 '} shadow flex w-[18rem] lg:w-[22rem] mx-auto  h-[10rem] rounded-3xl bg-white  `} >
                            <article className='w-[10rem] overflow-hidden text-white relative flex bg-pink-600 rounded-s-3xl ' >
                                <p className='text-3xl fw-semibold m-auto flex '>02 </p>
                                <span className='border-[30px] border-pink-200 -left-16 -bottom-16 w-32 h-32 rounded-full absolute  ' ></span>
                            </article>
                            <div className='flex w-full ' >
                                <img className='m-auto w-24 ' src={require('../../Assest/ideaCard.webp')} alt="Message" />
                            </div>

                        </div>
                        <span className='w-10 h-10 d-none d-sm-flex absolute top-1/2 -translate-y-1/2 -right-8 flex items-center justify-center 
                                    rounded-full text-white bg-violet-500 shadow-md shadow-violet-500 ' >
                            <RightArrow />
                        </span>
                    </article>
                    {/* Section 3 */}
                    <div className={` ${isVisible[4] ? 'fadein' : ' opacity-0 '} w-[20rem] lg:w-[24rem] my-6 sm:my-16`} >
                        <h4 className='text-3xl fw-semibold '>  Take Skill Assessments & Earn Certifications </h4>
                        <p className=' text-slate-600 text-sm' >
                            Test your skills through assessments and earn industry-recognized certifications to boost your career prospects
                        </p>
                    </div>
                    {/* Section 4 */}
                    <article className='relative w-full h-fit my-6 sm:my-16 ' >

                        <div className={` ${isVisible[4] ? 'fadein' : ' opacity-0 '} shadow flex w-[18rem] lg:w-[22rem] mx-auto  h-[10rem] rounded-3xl bg-white  `} >
                            <article className='w-[10rem] overflow-hidden text-white relative flex bg-pink-600 rounded-s-3xl ' >
                                <p className='text-3xl fw-semibold m-auto flex '>04 </p>
                                <span className='border-[30px] border-pink-200 -left-16 -bottom-16 w-32 h-32 rounded-full absolute  ' ></span>
                            </article>
                            <div className='flex w-full ' >
                                <img className='m-auto w-24 ' src={require('../../Assest/resumeCard.webp')} alt="Resume" />
                            </div>

                        </div>
                        <span className='w-10 h-10 d-none d-sm-flex absolute top-1/2 -translate-y-1/2 -right-8 flex items-center justify-center 
            rounded-full text-white bg-violet-500 shadow-md shadow-violet-500 ' >
                            <RightArrow />
                        </span>
                    </article>
                    {/* Section 5 */}
                    <div className={` ${isVisible[5] ? 'fadein' : ' opacity-0 '} w-[20rem] lg:w-[22rem] my-6 sm:my-20 `} >
                        <h4 className='text-3xl fw-semibold '> Get Interview Opportunities & Job Placements </h4>
                        <p className=' text-slate-600 text-sm' >
                            Gain access to exclusive interview opportunities with top companies, ensuring you're ready to launch your career.
                        </p>
                    </div>
                </section>
                {/* Col 2 */}
                <section className='col-md-6 flex border-s-2  flex-col items-center ' >
                    <article className='relative w-full h-fit my-6 sm:my-16 ' >

                        <div className={` ${isVisible[1] ? 'fadein' : ' opacity-0 '} shadow flex w-[18rem] lg:w-[22rem] mx-auto  h-[10rem] rounded-3xl bg-white  `} >
                            <article className='w-[10rem] overflow-hidden text-white relative flex bg-violet-600 rounded-s-3xl ' >
                                <p className='text-3xl fw-semibold m-auto flex '>01 </p>
                                <span className='border-[30px] border-violet-200 -left-16 -bottom-16 w-32 h-32 rounded-full absolute  ' ></span>
                            </article>
                            <div className='flex w-full ' >
                                <img className='m-auto w-24 ' src={require('../../Assest/learnCard.webp')} alt="Card" />
                            </div>

                        </div>
                        <span className='w-10 h-10 d-none d-sm-flex absolute top-1/2 -translate-y-1/2 -left-8 flex items-center justify-center 
                    rounded-full text-white bg-violet-500 shadow-md shadow-violet-500 ' >
                            <RightArrow />
                        </span>
                    </article>
                    {/* Section2 */}

                    <div className={` ${isVisible[3] ? 'fadein' : ' opacity-0 '} w-[20rem] lg:w-[23rem] my-6 sm:my-20`} >
                        <h4 className='text-3xl fw-semibold '>Gain Deeper Knowledge with Case Studies & Real Projects. </h4>
                        <p className=' text-slate-600 text-sm' >
                            Learn by doing! Work on real-world case studies and integrated projects to deepen your understanding and prepare you for actual industry challenges
                        </p>
                    </div>
                    {/* Section 3 */}
                    <article className='relative w-full h-fit my-6 sm:my-10 ' >
                        <div className={` ${isVisible[5] ? 'fadein' : ' opacity-0 '} shadow flex w-[18rem] lg:w-[22rem] mx-auto  h-[10rem] rounded-3xl bg-white `} >
                            <article className='w-[10rem] overflow-hidden text-white relative flex bg-indigo-600 rounded-s-3xl ' >
                                <p className='text-3xl fw-semibold m-auto flex '>03 </p>
                                <span className='border-[30px] border-indigo-200 -left-16 -bottom-16 w-32 h-32 rounded-full absolute  ' ></span>
                            </article>
                            <div className='flex w-full ' >
                                <img className='m-auto w-24 ' src={require('../../Assest/certificate.webp')} alt="Certificate" />
                            </div>

                        </div>
                        <span className='w-10 h-10 d-none d-sm-flex absolute top-1/2 -translate-y-1/2 -left-8 flex items-center justify-center 
rounded-full text-white bg-violet-500 shadow-md shadow-violet-500 ' >
                            <RightArrow />
                        </span>
                    </article>
                    {/* Section 4  */}
                    <div className={` ${isVisible[4] ? 'fadein' : ' opacity-0 '} w-[20rem] lg:w-[22rem] my-6 sm:my-16`} >
                        <h4 className='text-3xl fw-semibold '>Develop Interview Skills & Build Your Resume </h4>
                        <p className=' text-slate-600 text-sm' >
                            Our in-house HR team guides you through resume building and mock interviews, helping you make a lasting impression
                        </p>
                    </div>
                    {/* Section 5 */}
                    <article className='relative w-full h-fit my-6 ' >

                        <div className={` ${isVisible[5] ? 'fadein' : ' opacity-0 '} shadow flex w-[18rem]
                         lg:w-[22rem] mx-auto  h-[10rem] rounded-3xl bg-white `} >
                            <article className='w-[10rem] overflow-hidden text-white relative flex bg-indigo-600 rounded-s-3xl ' >
                                <p className='text-3xl fw-semibold m-auto flex '>05 </p>
                                <span className='border-[30px] border-indigo-200 -left-16 -bottom-16 w-32 h-32 rounded-full absolute  ' ></span>
                            </article>
                            <div className='flex w-full ' >
                                <img className='m-auto w-24 ' src={require('../../Assest/profileCard.webp')} alt="ProfileCard" />
                            </div>

                        </div>
                        <span className='w-10 h-10 d-none d-sm-flex absolute top-1/2 -translate-y-1/2 -left-8 flex items-center justify-center 
rounded-full text-white bg-violet-500 shadow-md shadow-violet-500 ' >
                            <RightArrow />
                        </span>
                    </article>
                </section>

            </main>

            <img src={require('../../Assest/companies.png')} alt="companies" />
        </div>
    )
}

export default HowItStarts