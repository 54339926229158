import React from 'react'
import Slider from 'react-slick';

const Testimonial = () => {
    var settings = {
        // dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        // rtl: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='relative min-h-[100vh] sm:min-h-[78vh] lg:min-h-[62vh] xl:min-h-[120vh] ' >
            <img className='w-full absolute xl:h-[90vh] object-cover    ' src={require('../../Assest/testimonialSection.png')} alt="Testimonial" />
            <main className=' xl:min-h-[70vh] p-3 relative top-40 bg-pink-50 container rounded-3xl ' >
                <section className='relative w-full  mt-10 sm:mt-0 pt-8 sm:pt-10 xl:pt-16 poppins'>
                    <h6 style={{ zIndex: 0 }}
                        className='text-5xl sm:text-8xl xl:text-9xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                        Testimonial
                    </h6>
                    <div style={{ zIndex: 6 }} className='relative '>
                        <h4 className=' text-3xl sm:text-4xl text-center poppins fw-semibold text-blue-800'>
                            Hear from Our Students
                        </h4>
                        <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-2xl fw-semibold'>
                            success stories
                            <img src={require('../../Assest/arrows2.png')} alt="Arrow"
                                className='w-[50px]' />
                        </h6>
                    </div>
                </section>
                <Slider {...settings} className='flex my-10 container custom-margin-all justify-between' >

                    <div className=' rounded poppins relative  w-[20rem] h-[20rem] my-14 '>
                        <img className=' absolute rotatex top-0 w-full ' src={require('../../Assest/testimonial2.png')}
                            alt="testimonial" />
                        <div className='relative w-[12rem] top-0 mx-auto' >
                            <img src={require('../../Assest/cmnt1.jpg')} className='mx-auto w-20 h-20 rounded-full mb-2' alt="Photo" />
                            <h5 className='text-center text-2xl  text-slate-600 ' > Pragathi S </h5>
                            {/* <p className='text-center text-slate-600 text-sm ' >
                                Lead Designer </p> */}
                            <p className='text-xs text-slate-600 line-clamp-4 mx-auto ' >
                                I’ve wanted to learn digital marketing for a while, and SkillLearningAcademy made it possible. The on-the-job training gave me hands-on experience, and the
                                projects helped me apply what I learned in real-world situations.
                            </p>
                        </div>
                    </div>

                    <div className=' rounded poppins relative w-[20rem] h-[20rem] my-14 '>
                        <img className='absolute rotatex top-0 ' src={require('../../Assest/testimonial1.png')}
                            alt="testimonial" />
                        <div className='relative w-[12rem] top-0 mx-auto' >
                            <img src={require('../../Assest/cmnt2.jpg')} className='mx-auto w-20 h-20 rounded-full mb-2' alt="Photo" />
                            <h5 className='text-center text-2xl  text-slate-600 ' > Pradeep </h5>
                            {/* <p className='text-center text-slate-600 text-sm ' > Lead Designer </p> */}
                            <p className='text-xs text-slate-600 line-clamp-4 ' >
                                As an MBA graduate, I wanted to add digital marketing skills to advance my career and offer freelance services. SkillLearningAcademy was the perfect choice! My trainer,
                                Mr. Karthik, was an expert,
                                sharing practical insights and teaching effective strategies.
                            </p>
                        </div>
                    </div>

                    <div className=' rounded poppins relative w-[20rem] h-[20rem] my-14 '>
                        <img className='absolute top-0 ' src={require('../../Assest/testimonial2.png')}
                            alt="testimonial" />
                        <div className='relative w-[12rem] top-0 mx-auto' >
                            <img src={require('../../Assest/abdulcomnt.jpg')} className='w-20 h-20 rounded-full object-cover mx-auto mb-2' alt="Photo" />
                            <h5 className='text-center text-2xl  text-slate-600 ' > Syed Abdul Fathah </h5>
                            {/* <p className='text-center text-slate-600 text-sm ' > Lead Designer </p> */}
                            <p className='text-xs text-slate-600 line-clamp-4 ' >
                                I’ve always been passionate about digital marketing and wanted to dive deeper into it.
                                Thanks to SkillLearningAcademy and their digital marketing course, I was able to do just that.
                            </p>
                        </div>
                    </div>

                </Slider>
            </main>
        </div>
    )
}

export default Testimonial