import React, { useContext, useState } from 'react'
import PinkTick from '../../Icons/PinkTick'
import FacebookIcon from '../../Icons/FacebookIcon'
import InstagramIcon from '../../Icons/InstagramIcon'
import YoutubeIcon from '../../Icons/YoutubeIcon'
import TwitterIcon from '../../Icons/TwitterIcon'
import BannerCourseCard from '../Card/BannerCourseCard'
import defaultImage from '../../Assest/Hero.webp';
import { SlaData } from '../../Context/SLAstore'
import { useNavigate } from 'react-router-dom'
import StudentContact from '../StudentContact'
const HomeBanner = ({ page, grd, image }) => {
    let backgroundimg = {}
    let navigate = useNavigate()
    let { allCourses } = useContext(SlaData)
    let [searchWord, setSearchWord] = useState('')
    let [studentShow, setStudentShow] = useState(false)

    let [filterList, setfilterList] = useState()
    let search = () => {
        let filteredArry = [...allCourses].filter((obj) => obj.Title.toLowerCase().indexOf(searchWord.toLowerCase()) != -1)
        setfilterList(filteredArry)
    }
    return (
        <div>
            <main onClick={() => setfilterList(false)} className={` xl:min-h-[89vh] relative flex ${grd ? grd : "bannerbg"} p-4 `} >
                <section className='absolute  gap-3 justify-center  items-center top-50 
                -translate-y-1/2 right-1 flex flex-col  ' >
                    <button className='icon text-violet-500 mx-1 hover:text-blue-400 duration-200 ' >
                        <FacebookIcon />
                    </button>
                    <button className='icon text-violet-500 mx-1 hover:text-blue-400 duration-200 ' >
                        <InstagramIcon />
                    </button>
                    <button className='icon text-violet-500 mx-1 hover:text-blue-400 duration-200 ' >
                        <YoutubeIcon />
                    </button>
                    <button className='icon text-violet-500 mx-1 hover:text-blue-400 duration-200 ' >
                        <TwitterIcon />
                    </button>

                </section>
                <button onClick={() => setStudentShow(true)}
                    className='btnbg 
                       fixed left-0 z-10 top-1/2 
                        rounded-r -translate-y-1/2  
                        text-white p-2 text-xs vrl ' >
                    Quick enquiry
                </button>
                <section
                    style={{
                        backgroundImage: `url(${image ? image : defaultImage})`,
                        backgroundSize: 'cover', // To make the image cover the entire area
                        backgroundPosition: 'center', // Center the image
                    }}
                    className=' m-auto row w-[98%] min-h-[70vh] xl:min-h-[80vh]
                  p-3 rounded-3xl ' >
                    <article className={` col-lg-7 flex flex-col
                         ${page != 'course' && "justify-between"} `}>
                        <>
                            <h4 className='text-5xl text-white xl:text-5xl poppins md:w-4/5 lg:w-4/6 xl:w-[72%] h-fit ' >
                                Extreme learning excellence with work
                                <span className='txtgrd'> integrated projects
                                </span>
                            </h4>
                            <p className='md:w-4/5 text-slate-300 poppins text-sm my-4 ' >Powerful, self-serve product and growth analytics to help you convert,
                                engage, and retain more. </p>

                            <div className='my-4 flex relative ' >
                                <input onKeyUp={(e) => {
                                    if (e.key == 'Enter')
                                        search()
                                }} type="text" value={searchWord} onChange={(e) => setSearchWord(e.target.value)}
                                    placeholder='Search your course... '
                                    className='p-2 border-2  w-2/3 sm:w-1/2  rounded outline-none
                                     text-white border-slate-600
                                     bg-slate-800 bg-opacity-75 ' />
                                <button onClick={() => search()} className='btnbg text-xs sm:text-base p-2 bg-opacity-90 rounded mx-2 text-white' >
                                    Search now
                                </button>
                                {filterList &&
                                    <section className='rounded z-10 w-2/3 sm:w-1/2 overflow-y-scroll searchscroll h-32 absolute 
                                text-white border-slate-700 border-2
                                     bg-slate-800 top-14 ' >
                                        {filterList.length > 0 ? filterList.map((obj, index) => (
                                            <button
                                                onClick={() => {
                                                    setSearchWord('');
                                                    navigate(`/${obj.slug}`)
                                                }}
                                                className='p-1 px-2 text-xs w-full text-start hover:bg-slate-700 ' > {obj.Title} </button>
                                        )) : <p className='p-2 text-sm '>No Result found </p>
                                        }
                                    </section>}
                            </div>


                            {page != 'course' && <p className='md:w-4/5 text-slate-300 poppins text-sm my-2 ' >
                                What we do to , build your profile
                                {/* <a className='text-slate-300  ' href=""> privacy policy. </a> */}
                            </p>}
                            {page != 'course' &&
                                <section className='flex gap-3 my-3' >
                                    <div className='flex gap-2 text-white items-center  ' >
                                        <span className='text-pink-200 ' >
                                            <PinkTick />
                                        </span>
                                        Multiple case studies
                                    </div>
                                    <div className='flex gap-2 text-white items-center ' >
                                        <span className='text-pink-200 ' >
                                            <PinkTick />
                                        </span>
                                        Multiple industry projects
                                    </div>
                                    <div className='flex d-none d-sm-flex gap-2 text-white items-center ' >
                                        <span className='text-pink-200 ' >
                                            <PinkTick />
                                        </span>
                                        Multiple certifications to build your profile
                                    </div>
                                </section>
                            }

                        </>
                        {page != 'course' &&
                            <section className='row mt-5 ' >
                                <div className=' p-2 col-6 col-md-3' >
                                    <div className='homebox p-2 h-full homeboxtext rounded-t-xl pb-4 ' >
                                        <p className=' fw-bold mb-0 '>Project Based
                                            <span className='block fw-normal '> Learning </span> </p>
                                    </div>
                                </div>
                                <div className=' p-2 col-6 col-md-3' >
                                    <div className='homebox p-2 h-full homeboxtext rounded-t-xl pb-4 ' >
                                        <p className=' fw-bold mb-0 '> Work Integrated
                                            <span className='block fw-normal '> Learning </span> </p>
                                    </div>
                                </div>
                                <div className=' p-2 col-6 col-md-3' >
                                    <div className='homebox p-2 h-full homeboxtext rounded-t-xl pb-4 ' >
                                        <p className=' fw-bold mb-0 '>Flexible Hybrid
                                            <span className='block fw-normal '> Learning </span> </p>
                                    </div>
                                </div>
                                <div className=' p-2 col-6 col-md-3' >
                                    <div className='homebox p-2 h-full homeboxtext rounded-t-xl pb-4 ' >
                                        <p className=' fw-bold mb-0 '>Guest lectures
                                            <span className='block fw-normal '>& workshops </span> </p>
                                    </div>
                                </div>
                            </section>
                        }
                        {
                            page == 'course' &&
                            <section className='absolute -bottom-36 lg:-bottom-28 xl:-bottom-20 d-none d-sm-block ' >
                                <article className='flex justify-between gap-3 ' >
                                    <BannerCourseCard heading="Work Integration" content='Engage with industry projects and gain practical skills. 
                                    Our industry experts guide you to become job-ready from day one.' />
                                    <BannerCourseCard heading='Project-Based Learning' content='Sharpen your skills through hands-on tasks. This 
                                    practical approach sets you up for success in your chosen field.' />
                                    <BannerCourseCard heading='Networking Opportunities' content='Connect with like-minded peers and industry 
                                    professionals. Find job opportunities through our active and supportive community.' />
                                </article>

                            </section>
                        }

                    </article>
                    <article className='col-lg-5 flex ' >
                        <button className='flex ms-auto mt-auto ' >
                            <img src={require('../../Assest/Googlereview.webp')}
                                className='w-[120px] ' alt="Google review" />
                        </button>
                    </article>
                </section>
            </main>
            <StudentContact show={studentShow} setshow={setStudentShow} />
        </div>
    )
}

export default HomeBanner