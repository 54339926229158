import React, { useEffect, useState } from 'react'
import RightArrow from '../../Icons/RightArrow'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { hostname } from '../../App'

const EventsNews = () => {
    let [allEvents, setEvents] = useState()
    useEffect(() => {
        axios.get(`${hostname}/event/EventsView/`).then((response) => {
            console.log(response.data, "events  ");
            setEvents([...response.data].reverse())
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    let navigate = useNavigate()
    return (
        <div className='min-h-[50vh] lg:min-h-[80vh] my-10 lg:mt-44 xl:my-0 xl:min-h-[88vh] flex ' >
            <main className='row container poppins  m-auto ' >
                <section className='col-xl-6 flex' >

                    <article className='my-auto relative w-full ' >
                        <p className='absolute text-center text-blue-800 fw-semibold rotate-45 bottom-0 right-0 sm:right-1/3 '>
                            Industrial <br />
                            Expert </p>
                        <h5 className='text-blue-800 text-2xl' >Digital Marketing Agency </h5>
                        <section className='relative w-full mt-10 sm:mt-0 pt-8 sm:pt-10 poppins'>
                            <h6 style={{ zIndex: 0 }}
                                className='text-5xl sm:text-9xl text-nowrap fw-bold absolute top-0  txtbgz'>
                                Updates
                            </h6>
                            <div style={{ zIndex: 6 }} className='relative flex gap-3 items-center'>
                                <h4 className=' text-3xl sm:text-5xl poppins fw-semibold text-blue-800'>
                                    Events <br />
                                    News
                                </h4>
                                <span className=' text-4xl sm:text-8xl poppins fw-semibold text-blue-800 '>
                                    & </span>
                            </div>

                            <button onClick={() => navigate('/events')} style={{ backgroundColor: '#5751E1' }}
                                className='rounded flex my-4 items-center gap-3 text-white p-2  ' >
                                All Events & News  <RightArrow />
                            </button>
                        </section>
                        <img src={require('../../Assest/arrows2.png')} alt="Arrow"
                            className='w-[80px] absolute left-1/3 -rotate-180 ' />
                    </article>
                </section>
                <section className='col-xl-6 eventbg flex flex-wrap justify-around gap-3 lg:min-h-[80vh] xl:min-h-[80vh] ' >

                    <div className='shadow relative rounded-xl h-fit shadow-violet-500 w-[13rem] md:w-[13rem] lg:w-[16rem] xl:w-[15rem] ' >
                        <img src={allEvents && allEvents[0] ? allEvents[0].events_img : require('../../Assest/Maskgroup.png')}
                            className='w-full rounded-t-xl h-[12rem] object-cover ' alt="Mask Image" />
                        <div className='p-3 bg-white rounded-b-xl '  >
                            <p className=' flex justify-between text-xs ' >
                                5,957 Students <span>
                                    {allEvents && allEvents[0] && allEvents[0].starting_date} </span>
                            </p>
                            <h6 className='fw-semibold mb-3 '>
                                {allEvents && allEvents[0] && allEvents[0].heading}</h6>
                        </div>
                        <button onClick={() => navigate(`/events/${allEvents && allEvents[0] ? allEvents[0].slug : ''}`)} className='p-2 rounded-full text-xs px-4 left-1/2 -translate-x-1/2 absolute -bottom-3 bg-violet-600 text-white ' >
                            Check Now </button>
                    </div>

                    <div className='shadow relative rounded-xl mt-8 h-fit shadow-violet-500 w-[13rem] md:w-[13rem] lg:w-[16rem] xl:w-[15rem] ' >
                        <img src={allEvents && allEvents[2] ? allEvents[2].events_img : require('../../Assest/Maskgroup.png')}
                            className='w-full rounded-t-xl ' alt="Mask Image" />
                        <div className='p-3 bg-white rounded-b-xl '  >
                            <p className=' flex justify-between text-xs ' > 5,957 Students
                                <span> {allEvents && allEvents[2] ? allEvents[2].starting_date : "01h 49m"} </span>
                            </p>
                            <h6 className='fw-semibold mb-3 '>
                                {allEvents && allEvents[2] ? allEvents[2].heading :
                                    `Motion Graphics: Create a Nice
                                Typography Animation `}
                            </h6>
                        </div>
                        <button onClick={() => navigate(`/events/${allEvents && allEvents[2] ? allEvents[2].slug : ""}`)} className='p-2 rounded-full text-xs px-4 left-1/2 -translate-x-1/2 absolute -bottom-3 bg-violet-600 text-white ' >
                            Check Now </button>
                    </div>
                    <div className='shadow relative rounded-xl h-fit shadow-violet-500 w-[13rem] md:w-[13rem] lg:w-[16rem] xl:w-[15rem] ' >
                        <img src={allEvents && allEvents[1] ? allEvents[1].events_img : require('../../Assest/Maskgroup.png')}
                            className='w-full rounded-t-xl h-[11rem] object-cover ' alt="Mask Image" />
                        <div className='p-3 bg-white rounded-b-xl '  >
                            <p className=' flex justify-between text-xs ' > 5,957 Students
                                <span>  {allEvents && allEvents[1] && allEvents[1].starting_date}  </span> </p>
                            <h6 className='fw-semibold mb-3 '> {allEvents && allEvents[1] && allEvents[1].heading} </h6>
                        </div>
                        <button onClick={() => navigate(`/events/${allEvents && allEvents[1] ? allEvents[1].slug : ''}`)}
                            className='p-2 rounded-full text-xs px-4 left-1/2 -translate-x-1/2 absolute -bottom-3 bg-violet-600 text-white ' >
                            Check Now </button>
                    </div>
                    <div className='shadow relative rounded-xl mt-8 h-fit shadow-violet-500 w-[13rem] md:w-[13rem] lg:w-[16rem] xl:w-[15rem] ' >
                        <img src={allEvents && allEvents[3] ? allEvents[3].events_img :
                            require('../../Assest/Maskgroup.png')} className='w-full rounded-t-xl ' alt="Mask Image" />
                        <div className='p-3 bg-white rounded-b-xl '  >
                            <p className=' flex justify-between text-xs ' > 5,957 Students
                                <span>  {allEvents && allEvents[3] ? allEvents[3].starting_date : "01h 49m"}

                                </span>
                            </p>
                            <h6 className='fw-semibold mb-3 '>
                                {allEvents && allEvents[3] ? allEvents[3].heading :
                                    `Motion Graphics: Create a Nice
                                Typography Animation `} </h6>
                        </div>
                        <button onClick={() => navigate(`/events/${allEvents && allEvents[3] ? allEvents[3].slug : ""}`)} className='p-2 rounded-full text-xs px-4 left-1/2 -translate-x-1/2 absolute -bottom-3 bg-violet-600 text-white ' >
                            Check Now </button>
                    </div>

                </section>

            </main>

        </div>
    )
}

export default EventsNews