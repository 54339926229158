import React, { useContext } from 'react'
import ArrowDown from '../../Icons/ArrowDown';
import Slider from 'react-slick';
import { SlaData } from '../../Context/SLAstore';
import { useNavigate } from 'react-router-dom';

const Blogs = () => {
    let { allblog, convertDate } = useContext(SlaData)
    let navigate = useNavigate()
    var settings = {
        // dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        // rtl: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    console.log(allblog);

    return (
        <div className=' xl:min-h-[88vh] py-4 ' >
            <section className='relative w-full mt-10 sm:mt-0 pt-8 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-9xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    Insights
                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                    Discover Our Latest Blogs
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
                    knowledge & tips
                        <img src={require('../../Assest/arrows.png')} alt="Arrow"
                            className='w-[50px]' />
                    </h6>
                </div>
            </section>


            <section className='container mx-auto  ' >

                <Slider {...settings} className=' ' >
                    {allblog && [...allblog].reverse().map((obj) => (
                        <div className='p-3' >

                            <div className=' w-[17rem] mx-auto rounded-xl homeblogbg my-5 border-2
border-violet-300 min-h-[300px] relative ' >
                                <img className='relative top-5 right-5 rounded-lg ' src={obj.img ? obj.img : require('../../Assest/Maskdummyblog.png')} alt="Dummy image" />
                                <div className='p-3 my-2 mb-4 poppins text-xs ' >
                                    <p className='mb-1 ' >{obj.created_at && convertDate(obj.created_at)} </p>
                                    <h5 className='fw-semibold line-clamp-2 ' > {obj.Main_Title} </h5>
                                    <p className='line-clamp-3 ' > {obj.Paragraph1} </p>
                                </div>
                                <span onClick={() => navigate(`/blogs/${obj.slug}`)}
                                    className=' absolute rounded -bottom-5 left-12 -rotate-90 cursor-pointer bg-white text-violet-500  p-2 border-2 border-violet-300' >
                                    <ArrowDown />
                                </span>
                            </div>

                        </div>
                    ))}
                </Slider>
            </section>

        </div>
    )
}

export default Blogs