import React from 'react'

const YoutubeIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_68_6679)">
                <path d="M15.086 15.9694H3.69658C1.6583 15.9694 0 14.3112 0 12.2728V6.50981C0 4.47149 1.6583 2.81323 3.69658 2.81323H15.086C17.1243 2.81323 18.7826 4.47149 18.7826 6.50981V12.2729C18.7826 14.3112 17.1243 15.9694 15.086 15.9694ZM3.69658 3.91378C2.26514 3.91378 1.10054 5.07833 1.10054 6.50981V12.2729C1.10054 13.7044 2.26514 14.8689 3.69658 14.8689H15.086C16.5175 14.8689 17.6821 13.7043 17.6821 12.2729V6.50981C17.6821 5.07833 16.5175 3.91378 15.086 3.91378H3.69658Z" />
                <path d="M7.56785 12.9156C7.47526 12.9156 7.38255 12.8922 7.29888 12.8453C7.12517 12.748 7.01758 12.5644 7.01758 12.3653V6.34605C7.01758 6.14575 7.12642 5.96126 7.30174 5.86441C7.47706 5.76753 7.69118 5.77358 7.86074 5.88023L12.7111 8.92983C12.8723 9.03115 12.9696 9.2086 12.9685 9.39896C12.9674 9.58931 12.8679 9.76558 12.7056 9.865L7.8552 12.8346C7.76712 12.8885 7.66756 12.9156 7.56785 12.9156ZM8.11812 7.342V11.3832L11.3745 9.38945L8.11812 7.342Z"/>
            </g>
            <defs>
                <clipPath id="clip0_68_6679">
                    <rect width="18.7826" height="18.7826" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default YoutubeIcon