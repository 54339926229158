import React from 'react'

const QuotesLeft = ({ size }) => {
    return (
        <svg width={size ? size : "54"} height={size ? size - 8 : "46"} viewBox="0 0 54 46" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.661133 22.643V45.286L11.6278 33.9796L22.5946 22.6732V11.3366V0H11.6278H0.661133V22.643ZM31.8313 22.643V45.286L42.798 33.9796L53.7647 22.6732V11.3366V0H42.798H31.8313V22.643Z"
                fill="currentColor" />
        </svg>

    )
}

export default QuotesLeft