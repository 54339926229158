import React from 'react'
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
    return (
        <div className=' h-[83vh]' >
            <Helmet>
                {/* <meta name='viewport' content='width=1024' /> */}
            </Helmet>
            <img src={require('../Assest/notFound.webp')} className='h-[90vh] object-cover w-full ' alt="Not Found webp" />



        </div>
    )
}

export default NotFoundPage