import React from 'react'

const TrophyIcon = ({ size }) => {
    return (
        <svg width={size ? size : "39"} height={size ? size : "39"} viewBox="0 0 39 39"
            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.6875 2.4375V0H7.3125V2.4375H0V10.9688C0 17.8781 5.25467 23.5804 11.9771 24.2964C13.4269 26.508 15.1529 28.0561 17.0625 28.7802V31.6875C17.0625 33.0336 15.9712 34.125 14.625 34.125C11.9327 34.125 9.75 36.3075 9.75 39H29.25C29.25 36.3075 27.0673 34.125 24.375 34.125C23.0288 34.125 21.9375 33.0336 21.9375 31.6875V28.7959C23.8512 28.0784 25.572 26.5094 27.0229 24.2964C33.7453 23.5804 39 17.8781 39 10.9688V2.4375H31.6875ZM4.875 10.9688V7.3125H7.37007C7.55634 11.4246 8.21525 15.1714 9.23911 18.3666C6.64822 16.9006 4.87499 14.1516 4.87499 10.9688H4.875ZM9.75 4.875V2.4375H12.1875V4.875C12.1875 16.0599 15.9104 25.2709 20.7188 26.6231C20.3187 26.7356 19.9137 26.8125 19.5 26.8125C14.1151 26.8125 9.75 16.9906 9.75 4.87499V4.875ZM34.125 10.9688C34.125 14.1516 32.3518 16.9006 29.7609 18.3666C30.7847 15.1714 31.4436 11.4246 31.6299 7.3125H34.125V10.9688H34.125Z" fill="white" />
        </svg>

    )
}

export default TrophyIcon