import axios from 'axios'
import React, { useContext, useState } from 'react'
import { hostname } from '../../App'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

const BlogComment = ({ id }) => {
    let [show, setshow] = useState(false)
    let [comntObj, setComntObj] = useState({
        name: '',
        email: '',
        comment: ''
    })
    let handleChange = (e) => {
        let { name, value } = e.target
        setComntObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let postComment = () => {
        console.log(otpEnter);
        console.log(otpRecevied);
        if (otpEnter == otpRecevied) {
            document.getElementById('otpError').innerHTML = ''
            axios.post(`${hostname}/blog/AddCommentView/${id}/`, comntObj).then((response) => {
                console.log(response.data);
                setshow(false)
                setOtpEnter('')
                setComntObj({
                    name: '',
                    email: '',
                    comment: ''
                })
            }).catch((error) => {
                console.log(error);
            })
        }
        else {
            document.getElementById('otpError').innerHTML = 'OTP miss matched'
        }
    }
    let [loading, setloading] = useState(false)
    let [otpEnter, setOtpEnter] = useState()
    let [otpRecevied, setOtpReceived] = useState()
    let emailVerification = () => {
        if (comntObj.email != '' && comntObj.name != '' && comntObj.comment != '') {
            setloading(true)
            document.getElementById('errormsg').innerHTML = ''
            axios.post(`${hostname}/event/EmailVerification/${comntObj.email}/`, comntObj).then((response) => {
                // postComment()
                setloading(false)
                setOtpReceived(response.data.message)
                document.getElementById('errormsg').innerHTML = ''
                console.log(response.data);
                setshow(true)
                toast.success('Comment has been Added')
            }).catch((error) => {
                setloading(false)
                console.log(error);
            })
        }
        else {
            setloading(false)
            document.getElementById('errormsg').innerHTML = 'Enter all the required field.'
        }
    }

    return (
        <div style={{ backgroundColor: 'rgb(247,247,250)' }}
            className=' my-2 rounded-xl p-4 '>
            <h4 className='fw-semibold mb-3 '>Post a Comment </h4>
            <p>Your email address will not be published. Required fields are marked. </p>
            <textarea name="comment" onChange={handleChange} value={comntObj.comment} id="" className='rounded-xl w-full outline-none p-2 border-1 bclr  '
                placeholder='Message here*' rows={7} > </textarea>
            <div className='flex flex-wrap gap-3 gap-sm-5'>
                <input type="text" className='border-1 w-64 outline-none bclr rounded p-2' name='name' onChange={handleChange}
                    value={comntObj.name} placeholder='Name *' />
                <input type="email" className='border-1 w-64 outline-none bclr rounded p-2' name='email' onChange={handleChange}
                    value={comntObj.email} placeholder='Email *' />
                {/* <input type="text" className='border-1 w-64 outline-none bclr rounded p-2' placeholder='Website' /> */}
            </div>
            {/* <div className='flex gap-2 my-3'>
                <button className='p-[10px] h-fit border-slate-950 rounded-sm border-1'> </button>
                <p className='mb-0  '>Save my name, email, and website in this browser for the next time I comment. </p>
            </div> */}
            <p id='errormsg' className='text-red-600 h-[20px] my-2'> </p>
            <button onClick={emailVerification} disabled={loading} className='grd p-2 px-3 my-2 rounded bclr2 border-1 uppercase text-white '> {loading ? "Loading..." : "post comments"} </button>
            {show && <Modal className='' show={show} centered onHide={() => {setshow(false);setOtpEnter('')}}>
                <Modal.Header closeButton>
                    OTP Verification
                </Modal.Header>
                <Modal.Body>
                    Enter the OTP :
                    <input type="number" value={otpEnter} onChange={(e) => {
                        if (e.target.value.length <= 6) { setOtpEnter(e.target.value) }
                    }} placeholder='14325' className='mx-2 border-1 rounded p-1 outline-none ' />
                    <p className='h-[20px] text-red-600 text-center ' id='otpError' > </p>
                    <button onClick={postComment} className='block p-2 px-3 rounded text-white bg-slate-500 my-3 ms-auto  '>
                        Comment
                    </button>
                </Modal.Body>
            </Modal>

            }
        </div>
    )
}

export default BlogComment