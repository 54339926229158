import React from 'react'

const CurveContainer = ({ Com, name, size, content }) => {
    return (
        <div className='p-4 my-2 text-sm mx-auto rounded-full w-[13rem] h-[17rem] py-5 border-2 border-violet-100
    flex flex-col justify-center shadow-md curvebox items-center ' >
            <div className='bg-violet-600 text-white w-fit p-3 rounded-full ' >
                <Com size={size ? size : 29} />
            </div>
            <p className='fw-bold my-3 text-base '>{name} </p>
            <p className='text-slate-700 text-xs fw-semibold text-center '> {content} </p>

        </div>
    )
}

export default CurveContainer