import React, { useState } from 'react'
import CourseContent from './CourseContent'
import CropCircle from './CropCircle'
import SideForm from './SideForm'
import CropCircle2 from './CropCircle2'

const CourseDetails = ({ data }) => {
    let [activeCircle, setActiveCircle] = useState(0)
    return (
        <div className='min-h-[88vh] h-fit bgsec ' >
            <img src={require('../../Assest/cube.png')} alt="Cube"
                className='relative top-20 w-20  ' />
            {/* <div className='h-[200px] bg-red-200 '></div> */}
            <main className='row container my-5 p-0 min-h-[60vh] mx-auto '>
                <section className='col-lg-8 col-xl-8 p-4 flex ' >
                    {/* <CropCircle /> */}
                    <CropCircle2 data={data} activeCircle={activeCircle} setActiveCircle={setActiveCircle} />
                </section>
                <section className='col-lg-4 lg:h-[55rem] sidescroll lg:overflow-y-scroll 
                col-xl-4 bg--900 flex ' >
                    <SideForm data={data} />
                </section>

            </main>
            {/* Content */}
            <CourseContent data={data} activeCircle={activeCircle} />

        </div>
    )
}

export default CourseDetails