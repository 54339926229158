import React from 'react'

const CheckBox = ({ size }) => {
    return (
        <svg width={size ? size : "16"} height={size ? size : "16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 7.33335L8 9.33335L14.6667 2.66669" stroke="#467DF7" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14 8V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H10.6667" stroke="#467DF7" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default CheckBox