import React from 'react'

const SuitCaseIcon = ({ size }) => {
    return (
        <svg width={size ? size : "512"} height={size ? size : "512"} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M244 280V320H268V280H244ZM276 291.92V324C276 326.24 274.24 328 272 328H240C237.76 328 236 326.24 236 324V291.92L76 258.56V396C76 407.04 84.96 416 96 416H416C427.04 416 436 407.04 436 396V258.56L276 291.92ZM330.4 120C328.4 106.32 316.48 96 302.64 96H209.36C195.52 96 183.6 106.32 181.6 120L178.16 144H194.4L196.64 128.16C197.92 118.96 205.92 112 215.2 112H296.8C306.08 112 314.08 118.96 315.36 128.16L317.6 144H333.84L330.4 120ZM432 152H80C73.36 152 68 157.36 68 164V248.72L76 250.4L236 283.76V276C236 273.76 237.76 272 240 272H272C274.24 272 276 273.76 276 276V283.76L436 250.4L444 248.72V164C444 157.36 438.64 152 432 152ZM330.4 120C328.4 106.32 316.48 96 302.64 96H209.36C195.52 96 183.6 106.32 181.6 120L178.16 144H194.4L196.64 128.16C197.92 118.96 205.92 112 215.2 112H296.8C306.08 112 314.08 118.96 315.36 128.16L317.6 144H333.84L330.4 120ZM330.4 120C328.4 106.32 316.48 96 302.64 96H209.36C195.52 96 183.6 106.32 181.6 120L178.16 144H194.4L196.64 128.16C197.92 118.96 205.92 112 215.2 112H296.8C306.08 112 314.08 118.96 315.36 128.16L317.6 144H333.84L330.4 120Z" fill="white" />
        </svg>

    )
}

export default SuitCaseIcon