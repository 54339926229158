import React from 'react'

const RocketIcon = ({ size }) => {
    return (
        <svg width={size ? size : "512"} height={size ? size : "512"} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M464 425.072C464 449.306 444.288 469.001 420.034 469.001H317.445C313.415 469.001 310.117 465.706 310.117 461.679C310.117 457.653 313.415 454.358 317.445 454.358H346.756C362.95 454.358 376.067 441.252 376.067 425.072C378.097 366.949 461.962 366.93 464 425.072Z" fill="currentColor" />
            <path d="M236.839 461.678C236.839 465.705 233.542 469 229.512 469H126.923C68.7594 466.97 68.7189 383.187 126.924 381.143C151.178 381.143 170.889 400.837 170.889 425.071C170.889 441.251 184.006 454.357 200.2 454.357H229.511C233.542 454.357 236.839 457.652 236.839 461.678Z" fill="currentColor" />
            <path d="M386.545 262.17C381.863 244.484 365.43 223.139 353.13 214.435C351.738 178.413 342.652 138.438 317.371 102.123C306.526 86.6016 293.336 72.6179 278.021 60.5373C275.31 58.4876 271.572 58.4876 268.935 60.5373C253.619 72.6179 240.356 86.6017 229.585 102.123C204.304 138.511 195.217 178.486 193.825 214.434C142.749 254.225 151.482 318.298 176.46 351.345C179.128 355.23 185.367 355.318 188.183 351.564C188.183 351.564 212.95 319.936 212.95 319.936L230.171 344.316H316.785L334.005 319.936L358.773 351.564C360.194 353.362 362.39 354.389 364.708 354.347C367.053 354.347 369.178 353.175 370.497 351.345C375.627 344.243 400.468 306.684 386.545 262.17ZM281.098 234.129C252.504 240 224.661 212.878 230.025 183.976C238.896 135.072 308.319 134.925 316.931 184.124C320.721 207.315 304.278 230.456 281.098 234.129ZM231.856 378.288C231.824 405.882 247.418 437.419 273.477 448.282C299.547 437.41 315.121 405.897 315.1 378.289C315.099 378.288 315.099 358.959 315.099 358.959H231.856V378.288Z" fill="currentColor" />
            <path d="M305.97 190.327C301.763 234.79 236.955 227.95 242.315 183.606C249.843 144.359 306.708 150.154 305.97 190.327Z" fill="currentColor" />
        </svg>

    )
}

export default RocketIcon